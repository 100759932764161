import { Card, CardContent,makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../store/actions/AuthActions";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => {
    return {
        dropdownContainer: {
            cursor: 'pointer',
            height: '2rem',
            padding: '5px 10px',
            width: '10rem',
            position: 'absolute',
            top: '100%',
            right: '0',
            boxShadow: '0 1px 1px 0 rgb(0 0 0/0.1),0 1px 1px 1.5px rgb(0 0 0/0.07)',
        },
        content: {
            width: '100%',
            height: '100%',
            padding: 0,
            '&:last-child': {
                paddingBottom: 0
            },
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }
    }
})

function LogoutDropdown(props){
    const classes = useStyles();
    const navigate = useNavigate();

    const handleLogout = () => {
        props.logout(navigate);
    }
    return (
        <Card onClick={handleLogout} elevation={0} className={classes.dropdownContainer} ref={props.setRef}>
            <CardContent className={classes.content}>
                <LogoutIcon/>
                <Typography variant="body2" noWrap>Logga ut</Typography>
            </CardContent>
        </Card>
    )
}

const mapStateToProps = ({ auth }) => ({
    
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (navigate) => dispatch(logout(navigate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutDropdown);