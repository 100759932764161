import axios from "axios"
import { v4 as uuid4 } from "uuid";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils"
import { BG_SENDER_SYSTEM, CURRENT_USER, ERROR_URL } from "../../utils/AppUtils"
import { ACTION_TYPES } from "../reducers/UserReducer"

const fetchUserRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_USER_BY_RIDE_ID)
    }
}

const fetchUserSuccess = (user) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_USER_BY_RIDE_ID),
        payload: user
    }
}

const fetchUserFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_USER_BY_RIDE_ID),
        payload: error
    }
}

export const getUserByRideId = (rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/users/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/users/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(fetchUserRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const user = responseData.user;
            dispatch(fetchUserSuccess(user));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchUserFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getUserByRidePathUrl = (pathUrl, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/users/path/' + pathUrl;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'analysis/users/path/' + pathUrl;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    return (dispatch) => {
        dispatch(fetchUserRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const user = responseData.user;
            dispatch(fetchUserSuccess(user));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchUserFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}