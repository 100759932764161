import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils";

export const ACTION_TYPES = {
    RESET_RELOADING: 'RESET_RELOADING',
    START_RELOADING: 'START_RELOADING',
    UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM'
}

const initState = {
    reloading: false,
    searchTerm: ''
};

const UtilsReducer = (state = initState, action) => {
    switch(action.type) {
        case ACTION_TYPES.RESET_RELOADING:
            return {
                ...state,
                reloading: false
            };
        case ACTION_TYPES.START_RELOADING:
            return {
                ...state,
                reloading: true
            };
        case ACTION_TYPES.UPDATE_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            };
        default: 
            return state;
    }
}

export default UtilsReducer;