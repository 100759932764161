import axios from "axios"
import { v4 as uuid4 } from "uuid";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils"
import { BG_SENDER_SYSTEM, CURRENT_USER, ERROR_URL } from "../../utils/AppUtils"
import { ACTION_TYPES } from "../reducers/SensorDataReducer";

function handleDownload(csvFile, rideId){
    const binaryString = window.atob(csvFile);
    const bytes = new Uint8Array(csvFile.length);
    const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));
    
    const blob = new Blob([arrayBuffer]);
    const fileName = 'sensor-data-ride' + rideId + '.csv';
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
        } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const downloadCsvRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV)
    }
}

const downloadCsvSuccess = (csvFile) => {
    return {
        type: SUCCESS(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV),
        payload: csvFile
    }
}

const downloadCsvFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV),
        payload: error
    }
}

export const getSensorDataAsCsv = (rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/users/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/corrected-sensordata-as-csv/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(downloadCsvRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'text/csv',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const csvFile = responseData.csvFile;
            dispatch(downloadCsvSuccess(csvFile));
            handleDownload(csvFile, rideId)
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(downloadCsvFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}
