import { Card, CardContent, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getAnalysisData } from '../../store/actions/RideActions';
import GraphGeneratorForAnalyze from '../GraphGeneratorForAnalyze';
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';


const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            position: 'relative',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        cardWrapper: {
            marginTop: '1.5rem',
            width: '100%',
        },
        cardContent: {
            padding: '1rem 0 2rem 0'
        },
        checkboxContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        checkboxWrapper: {
            padding: '1.5rem 2rem',
            display: 'flex',
            alignItems: 'center'
        },
        checkbox: {
            width: '1.4rem',
            height: '1.4rem'
        },
        labelX: {
            color: 'red'
        },
        labelY: {
            color: 'blue'
        },
        labelZ: {
            color: '#a6a6a6'
        },
        graphWrapper: {
            maxWidth: '91%',
            margin: 'auto',
            '@media (min-width: 300px)': {
                width: '20rem'
           },
           '@media (min-width: 350px)': {
            width: '23rem'
            },
            '@media (min-width: 450px)': {
                width: '26rem'
            },
            '@media (min-width: 550px)': {
                width: '30rem'
            },
            '@media (min-width: 768px)': {
                width: '40rem'
            },
            '@media (min-width: 1000px)': {
                width: '60rem'
            },
            '@media (min-width: 1400px)': {
                width: '70rem'
            }
        },
    }
  })

function GraphForSpecificRide({loading, getAnalysisData, analysisData, sensorDataIndex}) {
    const classes = useStyles();
    const {rideId} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [changeXdata, setChangeXData] = useState(false);
    const [changeYdata, setChangeYData] = useState(false);
    const [changeZdata, setChangeZData] = useState(false);

    useEffect(() => {
        getAnalysisData(rideId, navigate)
    },[]);

    useEffect(() => {
        getAnalysisData(rideId, navigate)
        return () => {
            dispatch({type: RIDE_ACTION_TYPES.CLEAR_ANALYSIS_DATA })
        }
    },[]);

    const handleCheckboxChecked = (e) => {
        const targetName = e.target.name;
        if(targetName === 'changeX'){
            setChangeXData(!changeXdata);
        }
        if(targetName === 'changeY'){
            setChangeYData(!changeYdata);
        }
        if(targetName === 'changeZ'){
            setChangeZData(!changeZdata);
        }
    }

    return (
        
        <div className={classes.root}>
            {!loading && analysisData.length ?
                <div className={classes.content}>
                    <div>
                        <Typography variant='h1' align='center'>Graf för ritt med id #{rideId}</Typography>
                    </div>
                    <Card elevation={0} className={classes.cardWrapper}>
                        <CardContent className={classes.cardContent}>
                                <div className={classes.graphWrapper}>
                                    <GraphGeneratorForAnalyze sensorDataIndex={sensorDataIndex} changeXdata={changeXdata} changeYdata={changeYdata} changeZdata={changeZdata} graphData={analysisData}/>
                                </div>
                        </CardContent>
                    </Card>
                    <div className={classes.checkboxContainer}>
                        <div className={classes.checkboxWrapper}>
                            <input className={classes.checkbox} type="checkbox" name="changeX" onChange={(e) => handleCheckboxChecked(e)} />
                            <label className={classes.labelX} htmlFor="changeX">Ändra X</label>
                        </div>
                        <div className={classes.checkboxWrapper}>
                            <input className={classes.checkbox} type="checkbox" name="changeY" onChange={(e) => handleCheckboxChecked(e)} />
                            <label className={classes.labelY}  htmlFor="changeY">Ändra Y</label>
                        </div>
                        <div className={classes.checkboxWrapper}>
                            <input className={classes.checkbox} type="checkbox" name="changeZ" onChange={(e) => handleCheckboxChecked(e)} />
                            <label className={classes.labelZ} htmlFor="changeZ">Ändra Z</label>
                        </div>
                    </div>
                </div>
            : 
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
            
        </div>
    )

    
}

const mapStateToProps = ({ ride }) => ({
    loading: ride.loading,
    analysisData: ride.analysisData,
    sensorDataIndex: ride.sensorDataIndex
})

const mapDispatchToProps = (dispatch, navigate) => {
    return {
        getAnalysisData: (rideId, navigate) => dispatch(getAnalysisData(rideId, navigate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphForSpecificRide);