import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center'
        },
    }
  })

function NotAllowed() {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
           
           NOT ALLOWED
            
        </div>
    )

    
}

const mapStateToProps = () => ({
 
})

const mapDispatchToProps = () => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotAllowed);