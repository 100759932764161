import { Navigate } from "react-router";
import { CURRENT_USER, LOGIN_URL, NOT_ALLOWED_URL } from "../../utils/AppUtils";

export function isAdmin() {
    let isAdmin = false;

    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    if(currentUser && currentUser.role === "admin"){
        isAdmin = true;
    }

    return isAdmin;
}

export function RequireAdmin({ children }){
    let isAuthenticated = false;

    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    if(currentUser){
        isAuthenticated = true;
    }
    
    if(!isAuthenticated){
        return <Navigate to={LOGIN_URL} replace/>;
    }
    return isAdmin() === true ? children : <Navigate to={NOT_ALLOWED_URL} replace/>;
}

export function RequireUser({ children }){
    let isAuthenticated = false;

    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    if(currentUser){
        isAuthenticated = true;
    }
    return isAuthenticated ? children : <Navigate to={LOGIN_URL} replace/>;
}
