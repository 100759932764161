import { Typography, makeStyles } from "@material-ui/core"
import { RISING_TROT, evaluateGaitTypeSwe } from "../utils/AppUtils";
import GraphGeneratorForCustomer from "./GraphGeneratorForCustomer";
import risingTrotProGraphSwe from '../assets/proffsgraf-lättridning-1440-swe.png'
import sittingTrotProGraphSwe from '../assets/proffsgraf-trav-nedsittning-1208-swe.png'
import mariSignature from '../assets/maris-signatur-edit.jpg'
import riderImage from '../assets/Ryttarbild.png'


const useStyles = makeStyles((theme) => {
    return {

        contentText: {
            fontSize: '1.2rem',
            color: '#6B6B6B',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        blackText: {
            fontSize: '1.2rem',
            color: 'black',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        riderImageWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                justifyContent: 'start',
                maxWidth: '50rem'
            }
        },
        riderImage: {
            width: '300px',
            maxWidth: '95%'
        },
        graphWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2rem auto',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                maxWidth: '50rem'
            },
        },
        extraTextContainer: {
            wordBreak: 'break-word'
        },
        proGraph: {
            maxWidth: '95%'
        },
        signatureContainer: {
            marginTop: '0.6rem',
            
            display: 'flex',
            justifyContent: 'center'
        }
       
}})

function SwedishCompletedAnalysisContent({user, ride, analysisData,resultTexts,extraText}) {

    const classes = useStyles();

    const handleExtraText = () => {

        //Make sure that pastes links becames a html link
        let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

        return extraText.replace(urlRegex, function (url) {
            return '<br><a href="' + url + '">' + url + '</a><br>';
        })

    }

    return (
        <div>
            <Typography variant="body1" className={classes.blackText}>
                <strong>Hej {user.userName},</strong>
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
                Så roligt att du vill bli mer medveten om din sits så att du kan förbättra samspelet
                mellan dig och din häst. Som du säkert vet så bidrar en bra sits till att hästen arbetar
                rätt och bygger de muskler som behövs för att bära upp ryttaren. Dessutom är en bra
                sits en förutsättning för att du ska kunna kommunicera framgångsrikt med hästen.
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
                Ryttaren har stort inflytande över hästens hälsa och välbefinnande. En välbalanserad
                och flexibel sits är en förutsättning för att du ska kunna ”swing with the horse” och
                skapa en gemensam rörelse med hästen utan att störa den.
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
                I Rider Analysis (ryttaranalysen) mäter vi hur du rör dig upp och ner och fram och
                tillbaka i trav (se skiss) och även hur jämna dina rörelser är.
            </Typography>
            <div className={classes.riderImageWrapper}>
                <img
                    src={riderImage}
                    alt="rider"
                    className={classes.riderImage}
                />
            </div>
            <Typography variant="body1" className={classes.blackText}>
                <strong><i>Vi har nu analyserat på din riddata och analysen visar/säger:</i></strong>
            </Typography>
            <Typography variant="body1" className={classes.blackText}>
                <strong>Så här ser dina rörelser (grafer) ut:</strong>
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
                Här ser du hur du rör dig i {evaluateGaitTypeSwe(ride.rideGaitType)}. Den röda kurvan visar din rörelse upp och
                ner och den grå din rörelse fram och tillbaka i sadeln.
            </Typography>
            <div className={classes.graphWrapper}>
                <GraphGeneratorForCustomer locale={ride.locale} graphData={analysisData} />
            </div>
            {resultTexts && resultTexts.map((text, index) => {
                return (
                    <Typography key={index} variant="body1" className={classes.contentText}>{text.text}</Typography>
                )
            })}

            {extraText !== null && extraText !== '' && extraText !== undefined ?
                <div className={classes.extraTextContainer}>
                    <Typography dangerouslySetInnerHTML={{ __html: handleExtraText(extraText) }} variant="body1" className={classes.contentText} />
                </div>
                : null
            }
            <br/>
            <Typography variant="body1" className={classes.blackText}>
                <strong>Jämför dig med elitryttaren!</strong>
            </Typography>
            {ride.rideGaitType === RISING_TROT ?

                //Specific text for RISING_TROT
                <>
                    <Typography variant="body1" className={classes.contentText}>
                        Så här ser det ut när en elitryttare rider lätt. Hen absorberar rörelsen upp och 
                        ned med sina höft-, knä och fotleder och stabiliserar sin sits med sina ben. 
                        Därför blir de röda kurvorna mjuka och vågformade och inte så höga vilket bidrar 
                        till mindre belastning på hästens rygg.
                    </Typography>
                    <Typography variant="body1" className={classes.contentText}>
                        Hen rör sig mjukt fram och tillbaka i sadeln och följer med bra i hästens rörelse 
                        framåt. Därför rör sig de grå kurvorna jämnt kring 0-strecket. Det beror på att 
                        ryttaren har en stark core och är följsam i sina höfter. Ryttaren är också jämn i 
                        sina rörelser vilket gör det lättare för hästen att röra sig i en jämn takt och i 
                        balans.
                    </Typography>
                </>

                :

                //Specific text for SITTING_TROT
                <>
                    <Typography variant="body1" className={classes.contentText}>
                        Så här ser det ut när en elitryttare rider. Hen absorberar rörelsen upp och ned med sina höft-, 
                        knä och fotleder samt nedre delen av ryggen. Därför blir de röda kurvorna mjuka och vågformade 
                        och inte så höga vilket bidrar till mindre belastning på hästens rygg.
                    </Typography>
                    <Typography variant="body1" className={classes.contentText}>
                        Hen rör sig mjukt fram och tillbaka i sadeln och följer med bra i hästens rörelse framåt. 
                        Därför rör sig de grå kurvorna jämnt kring 0-strecket. Det beror på att ryttaren har en 
                        stark core och är följsam i sina höfter. Ryttaren är också jämn i sina rörelser vilket 
                        gör det lättare för hästen att röra sig i en jämn rytm och i balans.
                    </Typography>
                </>   
            }
            
            <div className={classes.graphWrapper}>
                {ride.rideGaitType === RISING_TROT ?

                    //Graph for rising trot swe version
                    <>
                        <img
                        src={risingTrotProGraphSwe}
                        alt="graph"
                        className={classes.proGraph}
                        />
                    </>
                    
                    :
            
                    //Graph for sitting trot swe version
                    <>
                        <img
                        src={sittingTrotProGraphSwe}
                        alt="graph"
                        className={classes.proGraph}
                        />
                    </>   
                }
            </div>
            <Typography variant="body1" className={classes.blackText}>
                <strong>Vill du läsa mer om ryttarens rörelser i sadeln och om mätningen kan du klicka <a href="https://ridersposition.se/rideranalysis_veta_mer/">här!</a></strong>
            </Typography>
            <br/>
            <br/>
            <Typography align="left" variant="body1" className={classes.blackText}>
                <strong>Lycka till med din ridning och träning! Om du har några frågor skicka ett mail till: <a href="mailto:info@ridersposition.com">info@ridersposition.com</a> </strong>
            </Typography>
            <div className={classes.signatureContainer}>
                <img
                    src={mariSignature}
                    alt="maris signatur"
                />
            </div>
        </div>
    )

}

export default SwedishCompletedAnalysisContent;