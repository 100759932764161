import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { TODO_ANALYZES_URL } from "../../utils/AppUtils";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            justifyContent: 'center'
        },
        card: {
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        imageWrapper: {
            marginBottom: '2rem',
            '& .MuiSvgIcon-root':{
                fontSize: '10rem',
                color: 'rgb(245 179 53)'
            }
        },
        header:{
            fontWeight: '600'
        },
        link: {
            padding: '0.6rem 1.3rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.975rem',
            borderRadius: '0.9rem',
            fontWeight: '500',
            marginTop: '2rem'
          },
    }
  })

function DefaultErrorPage() {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <Card className={classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.imageWrapper}>
                        <ErrorOutlineSharpIcon className={classes.errorIcon} ></ErrorOutlineSharpIcon>
                    </div>
                    <Typography className={classes.header} variant="h5" align="center">Någonting gick fel</Typography>
                    <Typography variant="body1">Försök igen senare.</Typography>
                    <NavLink className={classes.link} to={TODO_ANALYZES_URL}>
                        <KeyboardBackspaceIcon/>&nbsp;&nbsp;Tillbaka till start
                    </NavLink>
                </CardContent>
            </Card>
        </div>
    )

    
}

const mapStateToProps = () => ({
 
})

const mapDispatchToProps = () => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultErrorPage);