
export const ACTION_TYPES = {
    EXECUTE_DEFAULT_ERROR: 'EXECUTE_DEFAULT_ERROR',
    EXECUTE_OTHER_ERROR: 'EXECUTE_OTHER_ERROR',
}

const initState = {
    errorMessage: '',
};

const executeDefaultError = (state,navigate) => {
    navigate("/error");
    return { ...state };
}

const executeOtherError = (state, action) => {

}

const ErrorHandlerReducer = (state = initState, action) => {
    switch(action.type) {
        case ACTION_TYPES.EXECUTE_DEFAULT_ERROR:
            return executeDefaultError(state, action.navigate);
        case ACTION_TYPES.EXECUTE_OTHER_ERROR:
            return executeOtherError(state, action)
        default: 
            return state;
    }
}

export default ErrorHandlerReducer;