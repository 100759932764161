import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ANALYZES_NOT_PURCHASED_URL, ANALYZES_DONE_URL, LOGO_URL, TODO_ANALYZES_URL } from "../utils/AppUtils";
import PersonIcon from '@mui/icons-material/Person';
import LogoutDropdown from "./LogoutDropdown";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import rpLogoSmall from '../assets/rp-logo.webp';

const useStyles = makeStyles((theme) => {
    return {
      appbar: {
        boxShadow: '0px 1px 1px -2px rgb(0 0 0 / 5%), 0px 1px 4px 0px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 12%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(5px)'
      },
      toolbar: {
        paddingLeft: 16,
        paddingRight: 16
      },
      imageWrapper: {
        padding: '10px 0 7px 0',
        marginRight: 10
      },
      logo: {
          width: 40
      },
      icons: {
        position: 'relative',
      },
      iconButton: {
        border: '1px solid lightGray',
        borderRadius: '0.75rem',
        padding: 10,
          '&:hover': {
            backgroundColor: 'transparent'
        }
      },
      links: {
        display: 'flex',
        flexGrow: 1
      },
      link: {
          padding: '0.700rem',
          color: 'gray',
          textDecoration: 'none',
          fontSize: '.875rem',
          position: 'relative',
          '&:hover': {
            backgroundColor: '#ececec',
            color: 'black',
            borderRadius: 5
        }
      },
    //   linkUnderline: {
    //     position: 'absolute',
    //     bottom: -12,
    //     left: '0.625rem',
    //     right: '0.625rem',
    //     backgroundColor: 'rgb(245 179 53)',
    //     height: 3,
    //     borderRadius: '0.25rem'
    //   }
    }
  })

function NavBar({isAdmin}) {
    const classes = useStyles();
    const [hideDropdown, setHideDropdown] = useState(true);
    const dropdown = useRef(null);

    useOnClickOutside(dropdown, () => showLogoutDropdown());

    const showLogoutDropdown = () => {
        setHideDropdown(!hideDropdown)
    }

    function useOnClickOutside() {
        useEffect(() => {
            const listener = (event) => {
                const element = dropdown.current;
                // Do nothing if clicking ref's element or descendent elements
                if(!element || element.contains(event.target)) {
                    return;
                } else {
                    setHideDropdown(!hideDropdown)
                }
            };
    
            document.addEventListener("mousedown", listener);
            return () => {
                document.addEventListener("mousedown", listener);
            }
    },[hideDropdown])};

    return (
        <AppBar
            className={classes.appbar}
            
        >
            <Toolbar className={classes.toolbar}>
                <div className={classes.imageWrapper}>
                    <img 
                        className={classes.logo} 
                        alt="logo" 
                        src={rpLogoSmall}
                    />
                </div>
                <div className={classes.links}>
                
                    <NavLink 
                        className={classes.link}
                        to={TODO_ANALYZES_URL}
                    >Analyser att göra
                        <div className={classes.linkUnderline}></div>
                    </NavLink >
                    <NavLink 
                        className={classes.link}
                        to={ANALYZES_DONE_URL}
                    >Färdiga analyser</NavLink >
                    <NavLink 
                        className={classes.link}
                        to={ANALYZES_NOT_PURCHASED_URL}
                    >Oköpta analyser</NavLink >
                </div>
                  
                <div className={classes.icons}>
                    <IconButton 
                        className={classes.iconButton}
                        onClick={showLogoutDropdown}
                        disabled={!hideDropdown}
                    >
                        <PersonIcon fontSize="small"/>
                    </IconButton>
                    {!hideDropdown && (
                        <LogoutDropdown setRef={dropdown}/>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = ({ auth }) => ({
    isAdmin: auth.isAdmin
})

const mapDispatchToProps = (dispatch) => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);