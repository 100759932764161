import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ACTION_TYPES as UTILS_ACTION_TYPES } from '../../src/store/reducers/UtilsReducer';


const useStyles = makeStyles((theme) => {
    return {
        textWrapper: {
            display: 'flex',
            flexDirection: 'column',
            margin: ' 0 auto 5rem auto',
            maxWidth: '50rem',
            padding: '0.5rem'
        },
        card: {
            marginTop: '0.5rem',
            borderRadius: 8,
            boxShadow: '0 1px 1px 0 rgb(0 0 0/0.1),0 1px 1px 2.5px rgb(0 0 0/0.07)',
            minHeight: '10rem'
        },
        textCardContent: {
            display: 'flex',
            flexDirection: 'column'
        },
        select: {
            padding: '0.5rem',
            borderRadius: 5,
            marginTop: 5
        },
        option: {
            padding: '3rem'
        },
        checkboxWrapper: {
            padding: '1.5rem 0',
            display: 'flex',
            alignItems: 'center'
        },
        checkbox: {
            width: '1.4rem',
            height: '1.4rem'
        },
        textArea: {
            width: '100%',
            minHeight: '10rem',
            minWidth: '100%',
            margin: 'auto',
            borderRadius: 5,
            border: 'solid lightGrey 2px',
            color: 'gray',
            fontSize: '18px'
        },
        textSumcontainer: {
            margin: '5rem 0'
        },
        totalText: {
            marginBottom: '1rem'
        },
        extraTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: ' 0 auto 5rem auto',
            maxWidth: '50rem',
            padding: '0.5rem'
        },
        buttonWrapper: {
            display:'flex',
            justifyContent: 'center'
        },
        button: {
            padding: '0.7rem 1.5rem',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.975rem',
            borderRadius: '0.7rem',
            fontWeight: '500',
            cursor: 'pointer',
            border: 'none'

        }
       
}})


function OutcomeTextsHandler({ outcomeTexts, saveAnalysis }) {
	const classes = useStyles();
    const [disabled, setDisabled] = useState([]);
    const [isTextEmpty,setIsTextEmpty] = useState(false);
    const [text,setText] = useState("")
    const [texts, setTexts] = useState([]); 
    const [extraText, setExtraText] = useState("");
    const [extraTextArray, setExtraTextArray] = useState([])
    const [formValue, setFormValue] = useState([]);
    const [numberOfOutcomTexts, setNumberOfOutcomeTexts] = useState(outcomeTexts.length); 
    const navigate = useNavigate();
    const {rideId} = useParams();
    const dispatch = useDispatch();

    /* Randomize array in-place using Durstenfeld shuffle algorithm */
    // const shuffleArray = (array) => {
    //     for (let i = array.length - 1; i > 0; i--) {
    //         let j = Math.floor(Math.random() * (i + 1));
    //         let temp = array[i];
    //         array[i] = array[j];
    //         array[j] = temp;
    //     }
    //     return array;
    // }

    const handleCheckboxChecked = (e, index) => {
        let newDisabledArray = [...disabled];
        let newArray = [...formValue]
        if(disabled[index]){
            if(disabled[index].disabled){
                newDisabledArray[index] = {disabled: false}
                newArray[index].text = texts[index].text1;
            }else {
                newDisabledArray[index] = {disabled: true}
                newArray[index].text = '';
            }
            setDisabled(newDisabledArray);
        }else {
            newDisabledArray[index] = {disabled:true}
            setDisabled(newDisabledArray);
            newArray[index].text = '';
        }
        setFormValue(newArray)
    }

    const handleChange = (event, index) => {
        setIsTextEmpty(false)
        let newArray = [...formValue];
        newArray[index].text = event.target.value;
        setFormValue(newArray);
    }

    const handleExtraTextChange = (event) => {
        setExtraText(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let empty = false;
        formValue.forEach(value => {
            const formText = value.text;
            if(formText.trim() === '') {
                empty = true;
                setIsTextEmpty(true)
            }
        })
        if(empty === true){
            return;
        } else {
            const request = {
                "resultTexts": formValue,
                "rideId": rideId,
                "extraText": extraText.trim() !== '' ? extraText : null
            }
            saveAnalysis(request, navigate)
            dispatch({type: UTILS_ACTION_TYPES.START_RELOADING })
        }
    }

    function loopOverTexts(n){
        var elements = [];
        for(let i = 0; i < n; i++){
            elements.push(
                <Typography className={classes.totalText} gutterBottom key={i} variant='body1'>{formValue[i].text}</Typography>
            );
        }
        return elements
    }

	useEffect(() => {
        setTexts(outcomeTexts);
        let tempFormValue = [];
        for(let i = 0; i < outcomeTexts.length; i++){

            const outcomeTextsArray = [];

            //Just add text that isn't empty
            if(outcomeTexts[i].text1 !== null && outcomeTexts[i].text1 !== undefined && outcomeTexts[i].text1.trim() !== ""){
                outcomeTextsArray.push(outcomeTexts[i].text1)
            } 
            if(outcomeTexts[i].text2 !== null && outcomeTexts[i].text2 !== undefined && outcomeTexts[i].text2.trim() !== ""){
                outcomeTextsArray.push(outcomeTexts[i].text2)
            } 
            if(outcomeTexts[i].text3 !== null && outcomeTexts[i].text3 !== undefined && outcomeTexts[i].text3.trim() !== ""){
                outcomeTextsArray.push(outcomeTexts[i].text3)
            }

            //Use length of array to get random number
            const arrayLength = outcomeTextsArray.length;
            const randomNumber = Math.floor(Math.random() * arrayLength) + 1;

            let randomText = ""

            //evaluate random text from random number
            for(let j = 1; j <= arrayLength; j++){
                if(randomNumber === j) {
                    randomText = outcomeTextsArray[j-1];
                    break;
                }
            }
            
            //const randomText = randomNumber === 1 ? outcomeTexts[i].text1 : randomNumber === 2 ? outcomeTexts[i].text2 : outcomeTexts[i].text3;
            const formObj = {
                'ruleSetName': outcomeTexts[i].ruleSetName,
                'text': randomText
            }
            tempFormValue.push(formObj);
        }
        setFormValue(tempFormValue);
	}, [outcomeTexts]);

    useEffect(() => {
        if(text.trim() !== '') {
            setIsTextEmpty(false)
        }
    }, [text]);

    useEffect(() => {
        if(extraText.trim() === ''){
            setExtraTextArray([])
        }else {
            setExtraTextArray(extraText.split('\n'));
        }
    }, [extraText]);

	return (
		<form onSubmit={handleSubmit}>
            {texts && texts.map((text, index) => {
                return (
                    <div className={classes.textWrapper} key={index}>
                        <Typography gutterBottom variant='h6'>{text.ruleSetName}</Typography>
                        <select 
                            disabled={disabled[index] && disabled[index].disabled} 
                            value={formValue[index].text} 
                            onChange={(e) => handleChange(e, index)}
                            className={classes.select}
                        >
                            {text.text1 !== null && text.text1 !== undefined && text.text1 !== "" ? <option className={classes.option} name="Textalternativ 1" value={text.text1}>{text.text1}</option> : null}
                            {text.text2 !== null && text.text2 !== undefined && text.text2 !== "" ? <option name="Textalternativ 2" value={text.text2}>{text.text2}</option> : null}
                            {text.text3 !== null && text.text3 !== undefined && text.text3 !== "" ? <option name="Textalternativ 3" value={text.text3}>{text.text3}</option> : null}
                        </select>
                        <div className={classes.checkboxWrapper}>
                            <input className={classes.checkbox} type="checkbox" name="freeText" onChange={(e) => handleCheckboxChecked(e,index)} />
                            <label htmlFor="freeText">Checka i här för att skriva egen text istället.</label>
                        </div>
                        <Card className={classes.card}>
                            <CardContent className={classes.textCardContent}>
                                {disabled[index] && disabled[index].disabled ?
                                    <textarea name={index} className={classes.textArea} value={formValue[index].text} onChange={(e) => handleChange(e, index)} /> 
                                :   <Typography variant='body1'>{formValue[index].text}</Typography>
                                }
                            </CardContent>
                        </Card>
                    </div>
                )
            })}
            {formValue.length ? 
                <div className={classes.textSumcontainer}>
                    <Typography variant="h6" align="center">Sammanställning av alla valda texter</Typography>
                    <div className={classes.textWrapper}>
                        <Card className={classes.card}>
                            <CardContent className={classes.textCardContent}>
                                {loopOverTexts(numberOfOutcomTexts)}
                                {extraTextArray && extraTextArray.map((line,index) => {
                                    return (
                                        <Typography className={classes.totalText} gutterBottom key={index} variant='body1'>{line}</Typography>
                                    )
                                })}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            : null}
            
            <div className={classes.extraTextContainer}>
                <Typography variant="h6" align="center">Här kan du lägga till ytterligare text</Typography>
                <Card className={classes.card}>
                    <CardContent className={classes.textCardContent}>
                        <textarea name="extra" className={classes.textArea} value={extraText} onChange={(e) => handleExtraTextChange(e)}/> 
                    </CardContent>
                </Card>
            </div>
            
            {isTextEmpty ? <Typography gutterBottom align='center' color='error'>Inga texter får vara tomma!</Typography> : null}
            <div className={classes.buttonWrapper}>
                <button type='submit' className={classes.button}>Spara analys</button>
            </div>
        </form>
	);
	
}
export default OutcomeTextsHandler;