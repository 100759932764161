import { Card, CardContent, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { useEffect, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { startAnalysis, getRideById, saveNotesForRide } from '../../store/actions/RideActions';
import { getUserByRideId } from '../../store/actions/UserActions';
import { ACTION_TYPES as USER_ACTION_TYPES } from '../../store/reducers/UserReducer';
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { evaluateGaitTypeSwe, RISING_TROT, SITTING_TROT } from '../../utils/AppUtils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            position: 'relative'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        card: {
            marginTop: '2.5rem',
            width: '20rem',
            backgroundColor: 'transparent'
        },
        cardContent: {
            padding: '1rem 0 2rem 0'
        },
        rowSection: {
            padding: '1rem 0 0rem 0'
        },
        row: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '0.5rem'
        },
        text: {
            fontSize: 15
        },
        notesHeader: {
            display: 'flex',
            alignItems: 'center'
        },
        iconButton: {
            marginLeft: '0.5rem',
        },
        saveButton: {
            backgroundColor: 'rgb(245 179 53)',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '13rem',
            margin: '1rem auto 0rem auto',
            padding: '0.5rem',
            borderRadius: '0.7rem'
        },
        saveButtonText: {
            color: 'white',
        },
        saveIcon: {
            marginLeft: '0.5rem'
        },
        notesCard: {
            marginTop: '0.5rem',
            marginBottom: '4rem',
            borderRadius: 8,
            boxShadow: '0 1px 1px 0 rgb(0 0 0/0.1),0 1px 1px 2.5px rgb(0 0 0/0.07)',
            minHeight: '5rem',
            width: '15rem',
            '@media (min-width: 350px)': {
                width: '20rem',
                },
            '@media (min-width: 500px)': {
                width: '30rem',
           },
           '@media (min-width: 769px)': {
            width: '40rem',
         },
           
            
        },
        notesContent: {

        },
        textArea: {
            width: '100%',
            minHeight: '5rem',
            minWidth: '100%',
            margin: 'auto',
            borderRadius: 5,
            border: 'solid lightGrey 2px',
            color: 'gray',
            fontSize: '18px'
        },
        buttonWrapper: {
            display:'flex',
            justifyContent: 'center'
        },
        rideHeader: {
            color: 'gray'
        },
        button: {
            padding: '0.7rem 1.5rem',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.975rem',
            borderRadius: '0.7rem',
            fontWeight: '500',
            cursor: 'pointer',
            border: 'none'

        }
    }
  })

function StartAnalysis({loading, startAnalysis, user, getUserByRideId, getRideById, ride, saveNotesForRide }) {
    const classes = useStyles();
    const {rideId} = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false); 
    const [formValue, setFormValue] = useState('');

    const handleStartAnalysis = (rideId) => {
        startAnalysis(rideId, navigate);
        //navigate(DO_ANALYSIS_URL + rideId)
        setIsLoading(true)
    }

    const handleSaveNotes = (event) => {
        event.preventDefault()
        const request = {
            "notes": formValue
        }
        saveNotesForRide(request, rideId, navigate)
    }

    const handleNotesChange = (event) => {
        setFormValue(event.target.value);
    }

    const handleClickEdit = () => {
        if(ride) {
            setFormValue(ride.notes)
        }
        setEditMode(!editMode)
    }

    useEffect(() => {
        getUserByRideId(rideId, navigate);
        return () => {
            dispatch({type: USER_ACTION_TYPES.CLEAR_USER })
        }
    },[]);

    useEffect(() => {
        getRideById(rideId, navigate);
        return () => {
            dispatch({type: RIDE_ACTION_TYPES.CLEAR_RIDE })
        }
    },[]);

    useEffect(() => {
        setEditMode(false)
    },[ride]);
    
    return (
        <div className={classes.root}>
            {!loading && ride && user && user.userName && !isLoading ? 
                <div className={classes.content}>
                    <div>
                        <Typography variant='h1' align='center'>Rider's Positions analyseringsverktyg</Typography>
                        <Typography variant='body1' align='center'>Tryck på "Starta analys" för att påbörja en RiderAnalysis av nedan vald ritt.</Typography>
                    </div>
                    <Card elevation={0} className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <div>
                                <Typography align='center' className={classes.rideHeader} variant='h6'>Ritt med ID #{rideId}</Typography>
                                <div className={classes.rowSection}>
                                    <div className={classes.row}>
                                        <Typography variant='h6' className={classes.text}>Skapad</Typography>
                                        <Typography variant='body1' className={classes.text}>{new Date(Date.parse(ride.createdAt)).toLocaleString("sv-SE").toString()}</Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography variant='h6' className={classes.text}>Ryttare</Typography>
                                        <Typography variant='body1' className={classes.text}>{user.userName}</Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography variant='h6' className={classes.text}>Email</Typography>
                                        <Typography variant='body1' className={classes.text}>{user.email}</Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography variant='h6' className={classes.text}>Gångart</Typography>
                                        <Typography variant='body1' className={classes.text}>{evaluateGaitTypeSwe(ride.rideGaitType)}</Typography>
                                    </div>
                                </div>
                                
                            </div>
                        </CardContent>
                    </Card>
                    <div className={classes.notesHeader}>
                        <Typography variant='h6' className={classes.text}>Anteckningar</Typography>
                        <IconButton className={classes.iconButton} onClick={() => handleClickEdit()}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </div>
                    <Card className={classes.notesCard}>
                        <CardContent className={classes.notesContent}>
                            {editMode ?
                                <form onSubmit={handleSaveNotes}>
                                    <textarea className={classes.textArea} value={formValue} onChange={(e) => handleNotesChange(e)}/>
                                    <button type='submit' className={classes.saveButton}>
                                        <Typography variant="body1" className={classes.saveButtonText}>Spara Anteckningar</Typography>
                                        <SaveIcon className={classes.saveIcon}/>
                                    </button>
                                </form>
                                
                            :
                                <Typography variant='body1'>{ride.notes}</Typography>
                            }
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrapper}>
                        <button onClick={() => handleStartAnalysis(rideId)} className={classes.button}>Starta analys</button>
                    </div>
                </div>
            : 
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
            
        </div>
    )

    
}

const mapStateToProps = ({ ride, user }) => ({
    response: ride.response,
    loading: ride.loading,
    user: user.user,
    startedAnalysis: ride.startedAnalysis,
    ride: ride.ride
})

const mapDispatchToProps = (dispatch) => {
    return {
        startAnalysis: (rideId, navigate) => dispatch(startAnalysis(rideId, navigate)),
        getUserByRideId: (rideId, navigate) => dispatch(getUserByRideId(rideId, navigate)),
        getRideById: (rideId, navigate) => dispatch(getRideById(rideId, navigate)),
        saveNotesForRide: (request, rideId, navigate) => dispatch(saveNotesForRide(request, rideId, navigate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartAnalysis);