import { useState } from "react";

export const useShareableState = () => {
    const [sensorData, setSensorData] = useState([]);
    const [reloading, setReloading] = useState(false);
    const [startSearch, setStartSearch] = useState(false)
    return {
        sensorData,
        setSensorData,
        reloading,
        setReloading,
        startSearch,
        setStartSearch
    }
}