import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getFilteredSensorDataByPathUrl, getResultTextsByPathUrl, getRideByPathUrl } from "../../store/actions/RideActions";
import { getUserByRidePathUrl } from "../../store/actions/UserActions";
import { ACTION_TYPES as USER_ACTION_TYPES } from '../../store/reducers/UserReducer';
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';
import { ENG } from "../../utils/AppUtils";
import SwedishCompletedAnalysisContent from "../SwedishCompletedAnalysisContent";
import EnglishCompletedAnalysisContent from "../EnglishCompletedAnalysisContent";
import riderAnalysisLogo from '../../assets/riderAnalysisLogo.png';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '1rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            position: 'relative',
        },
        page: {
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            margin: 'auto',
            '@media (min-width: 700px)': {
                maxWidth: '90%',
            },
            '@media (min-width: 1068px)': {
                maxWidth: '80rem'
            },
        },
        top: {
            maxWidth: '10rem',
            margin: '0 auto 2rem auto'
        },
        logo: {
            width: '10rem'
        },
        header: {
            backgroundColor: 'rgb(245 179 53)',
            padding: '0.5rem 0.2rem'
        },
        headerText: {
            fontSize: 12,
            color: 'black',
            fontWeight: '700',
            textAlign: 'center'
        },
        content: {
            margin: 'auto',
            '@media (min-width: 700px)': {
                maxWidth: '90%',
            },
            '@media (min-width: 1068px)': {
                maxWidth: '50rem'
            },
        },
        extraTextContainer: {
            wordBreak: 'break-word'
        },
        contentText: {
            fontSize: '1.2rem',
            color: '#6B6B6B',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        blackText: {
            fontSize: '1.2rem',
            color: 'black',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        riderImageWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                justifyContent: 'start',
                maxWidth: '50rem'
            }
        },
        riderImage: {
            width: '300px',
            maxWidth: '95%'
        },
        graphWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2rem auto',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                maxWidth: '50rem'
            },
        },
        proGraph: {
            maxWidth: '95%'
        },
        signatureContainer: {
            marginTop: '0.6rem',
            
            display: 'flex',
            justifyContent: 'center'
        }
    }
})

function CompletedAnalysis({ getResultTextsByPathUrl, resultTexts, extraText, analysisData, loading, getFilteredSensorDataByPathUrl, getUserByRidePathUrl, user, getRideByPathUrl, ride }) {
    const classes = useStyles();
    const { pathUrl } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        getFilteredSensorDataByPathUrl(pathUrl, navigate)
        getResultTextsByPathUrl(pathUrl, navigate)
        getUserByRidePathUrl(pathUrl, navigate)
        getRideByPathUrl(pathUrl, navigate)
        return () => {
            dispatch({ type: RIDE_ACTION_TYPES.CLEAR_ANALYSIS_DATA })
            dispatch({ type: RIDE_ACTION_TYPES.CLEAR_RESULT_TEXTS })
            dispatch({ type: USER_ACTION_TYPES.CLEAR_USER })
            dispatch({ type: RIDE_ACTION_TYPES.CLEAR_RIDE })
        }
    }, []);

    return (
        <div className={classes.root}>
            {!loading && analysisData.length > 0 && resultTexts.length > 0 && user && ride ?
                <div className={classes.page}>
                    <div className={classes.top}>
                        <div>
                            <img
                                src={riderAnalysisLogo}
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                    </div>
                    <div className={classes.content}>

                        {ride.locale === ENG ? 
                            
                            /* English content version */
                            <EnglishCompletedAnalysisContent user={user} ride={ride} analysisData={analysisData} resultTexts={resultTexts} extraText={extraText} />
                            
                        : 
                        
                            /* Swedish content version */
                            <SwedishCompletedAnalysisContent user={user} ride={ride} analysisData={analysisData} resultTexts={resultTexts} extraText={extraText} />
                            
                        }
                    </div>
                </div>
                :
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
        </div>
    )
}

const mapStateToProps = ({ ride, user }) => ({
    loading: ride.loading,
    analysisData: ride.analysisData,
    resultTexts: ride.resultTexts,
    extraText: ride.extraText,
    user: user.user,
    ride: ride.ride
})

const mapDispatchToProps = (dispatch) => {
    return {
        getFilteredSensorDataByPathUrl: (pathUrl, navigate) => dispatch(getFilteredSensorDataByPathUrl(pathUrl, navigate)),
        getResultTextsByPathUrl: (pathUrl, navigate) => dispatch(getResultTextsByPathUrl(pathUrl, navigate)),
        getUserByRidePathUrl: (pathUrl, navigate) => dispatch(getUserByRidePathUrl(pathUrl, navigate)),
        getRideByPathUrl: (pathUrl, navigate) => dispatch(getRideByPathUrl(pathUrl, navigate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedAnalysis);