import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils";

export const ACTION_TYPES = {
    DOWNLOAD_SENSOR_DATA_AS_CSV: 'DOWNLOAD_SENSOR_DATA_AS_CSV'
}

const initState = {
    loading: false,
    errorMessage: null,
    csvFile: null
};

const SensorDataReducer = (state = initState, action) => {
    switch(action.type) {
        case REQUEST(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV):
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.DOWNLOAD_SENSOR_DATA_AS_CSV):
            return {
                ...state,
                loading: false,
                csvFile: action.payload,
            };
        default: 
            return state;
    }
}

export default SensorDataReducer;