import axios from "axios"
import { v4 as uuid4 } from "uuid";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils"
import { ANALYSIS_COMPLETED, ANALYSIS_IN_PROCESS, ANALYSIS_NOT_PURCHASED, BG_SENDER_SYSTEM, CONFIRMATION_PAGE_URL, CURRENT_USER, DO_ANALYSIS_URL, ERROR_URL, H_SEARCH_TERM, PAGE, PAGE_SIZE, SORT_DIRECTION, SORT_FIELD, STATUS} from "../../utils/AppUtils"
import { ACTION_TYPES } from "../reducers/RideReducer"
import { ACTION_TYPES as UTILS_ACTION_TYPES } from '../../store/reducers/UtilsReducer';

const fetchRideByIdRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RIDE_BY_ID)
    }
}

const fetchRideByIdSuccess = (ride) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RIDE_BY_ID),
        payload: ride
    }
}

const fetchRideByIdFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RIDE_BY_ID),
        payload: error
    }
}

const fetchRideByPathUrlRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL)
    }
}

const fetchRideByPathUrlSuccess = (ride) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL),
        payload: ride
    }
}

const fetchRideByPathUrlFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL),
        payload: error
    }
}

const fetchRidesInProcessRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RIDES_IN_PROCESS)
    }
}

const fetchRidesInProcessSuccess = (rides, numOfRides) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RIDES_IN_PROCESS),
        rides: rides,
        numOfRides: numOfRides
    }
}

const fetchRidesInProcessFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RIDES_IN_PROCESS),
        payload: error
    }
}

const fetchRidesWithCompletedStatusRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RIDES_COMPLETED)
    }
}

const fetchRidesWithCompletedStatusSuccess = (rides, numOfRides) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RIDES_COMPLETED),
        rides: rides,
        numOfRides: numOfRides
    }
}

const fetchRidesWithCompletedStatusFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RIDES_COMPLETED),
        payload: error
    }
}

const fetchRidesWithNotPurchasedStatusRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED)
    }
}

const fetchRidesWithNotPurchasedStatusSuccess = (rides, numOfRides) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED),
        rides: rides,
        numOfRides: numOfRides
    }
}

const fetchRidesWithNotPurchasedStatusFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED),
        payload: error
    }
}


const startAnalysisRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.START_ANALYSIS)
    }
}

const startAnalysisSuccess = (response) => {
    return {
        type: SUCCESS(ACTION_TYPES.START_ANALYSIS),
        payload: response
    }
}

const startAnalysisFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.START_ANALYSIS),
        payload: error
    }
}

const getKpisRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID)
    }
}

const getKpisSuccess = (kpis) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID),
        kpis: kpis
    }
}

const getKpisFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID),
        payload: error
    }
}

const getOutcomeTextsRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID)
    }
}

const getOutcomeTextsSuccess = (outcomeTexts) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID),
        outcomeTexts: outcomeTexts
    }
}

const getOutcomeTextsFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID),
        payload: error
    }
}

const getResultTextsRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL)
    }
}

const getResultTextsSuccess = (resultTexts, extraText) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL),
        resultTexts: resultTexts,
        extraText: extraText
    }
}

const getResultTextsFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL),
        payload: error
    }
}

const getAnalysisDataRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.FETCH_ANALYSIS_DATA)
    }
}

const getAnalysisDataSuccess = (analysisData, index) => {
    return {
        type: SUCCESS(ACTION_TYPES.FETCH_ANALYSIS_DATA),
        analysisData: analysisData,
        index: index
    }
}

const getAnalysisDataFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.FETCH_ANALYSIS_DATA),
        payload: error
    }
}

const saveAnalysisRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.SAVE_FINISHED_ANALYSIS)
    }
}

const saveAnalysisSuccess = (response) => {
    return {
        type: SUCCESS(ACTION_TYPES.SAVE_FINISHED_ANALYSIS),
        response: response
    }
}

const saveAnalysisFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.SAVE_FINISHED_ANALYSIS),
        payload: error
    }
}

const saveNotesRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.SAVE_NOTES_FOR_RIDE)
    }
}

const saveNotesSuccess = (ride) => {
    return {
        type: SUCCESS(ACTION_TYPES.SAVE_NOTES_FOR_RIDE),
        payload: ride
    }
}

const saveNotesFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.SAVE_NOTES_FOR_RIDE),
        payload: error
    }
}

const saveCorrectedSensorDataRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA)
    }
}

const saveCorrectedSensorDataSuccess = (response) => {
    return {
        type: SUCCESS(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA),
        payload: response
    }
}

const saveCorrectedSensorDataFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA),
        payload: error
    }
}

const archiveRideRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.ARCHIVE_RIDE_BY_ID)
    }
}

const archiveRideSuccess = (status) => {
    return {
        type: SUCCESS(ACTION_TYPES.ARCHIVE_RIDE_BY_ID),
        status: status
    }
}

const archiveRideFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.ARCHIVE_RIDE_BY_ID),
        payload: error
    }
}

export const getRideById = (rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/rides/filter/purchased?offset=' + offset + '&first=' + first + '&status=' + ANALYSIS_IN_PROCESS;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(fetchRideByIdRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const ride = responseData.ride;
            dispatch(fetchRideByIdSuccess(ride));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRideByIdFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getRideByPathUrl = (pathUrl, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/rides/filter/purchased?offset=' + offset + '&first=' + first + '&status=' + ANALYSIS_IN_PROCESS;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/ride-by-path-url/' + pathUrl;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
   
    return (dispatch) => {
        dispatch(fetchRideByPathUrlRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const ride = responseData.ride;
            dispatch(fetchRideByPathUrlSuccess(ride));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRideByPathUrlFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getAllRidesInProcess = (pagination, navigate, sort, searchTerm) => {
    let sortField = sort.field;
    let sortDirection = sort.sortDirection;
    let page = pagination.page;
    let pageSize = pagination.pageSize;

    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/filter?' + PAGE + '=' + page + '&' + PAGE_SIZE + '=' + pageSize + '&' + STATUS + '=' + ANALYSIS_IN_PROCESS + '&' + SORT_FIELD + '=' + sortField + '&' + SORT_DIRECTION + '=' + sortDirection + '&' + H_SEARCH_TERM + '=' + searchTerm;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(fetchRidesInProcessRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const ridesInProcess = responseData.rideList;
            const numOfRides = responseData.totalRides;
            //const ridesInProcess = rideList.filter(ride => ride.rideAnalysisStatus === 'ANALYSIS_IN_PROCESS');
            dispatch(fetchRidesInProcessSuccess(ridesInProcess, numOfRides));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRidesInProcessFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getAllRidesWithCompletedAnalysis = (pagination, navigate, sort, searchTerm) => {
    let sortField = sort.field;
    let sortDirection = sort.sortDirection;
    let page = pagination.page;
    let pageSize = pagination.pageSize;

    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/filter?' + PAGE + '=' + page + '&' + PAGE_SIZE + '=' + pageSize + '&' + STATUS + '=' + ANALYSIS_COMPLETED + '&' + SORT_FIELD + '=' + sortField + '&' + SORT_DIRECTION + '=' + sortDirection + '&' + H_SEARCH_TERM + '=' + searchTerm;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(fetchRidesWithCompletedStatusRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const numOfRides = responseData.totalRides;
            const ridesWithCompletedStatus = responseData.rideList;
            //const ridesWithCompletedStatus = rideList.filter(ride => ride.rideAnalysisStatus === 'ANALYSIS_COMPLETED');
            dispatch(fetchRidesWithCompletedStatusSuccess(ridesWithCompletedStatus, numOfRides));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRidesWithCompletedStatusFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getAllRidesWithNotPurchasedStatus = (pagination, navigate, sort, searchTerm) => {
    let sortField = sort.field;
    let sortDirection = sort.sortDirection;
    let page = pagination.page;
    let pageSize = pagination.pageSize;

    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/filter?' + PAGE + '=' + page + '&' + PAGE_SIZE + '=' + pageSize + '&' + STATUS + '=' + ANALYSIS_NOT_PURCHASED + '&' + SORT_FIELD + '=' + sortField + '&' + SORT_DIRECTION + '=' + sortDirection + '&' + H_SEARCH_TERM + '=' + searchTerm;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return (dispatch) => {
        dispatch(fetchRidesWithNotPurchasedStatusRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const numOfRides = responseData.totalRides;
            const ridesWithNotPurchasedStatus = responseData.rideList;
            //const ridesWithCompletedStatus = rideList.filter(ride => ride.rideAnalysisStatus === 'ANALYSIS_COMPLETED');
            dispatch(fetchRidesWithNotPurchasedStatusSuccess(ridesWithNotPurchasedStatus, numOfRides));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRidesWithNotPurchasedStatusFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}


export const startAnalysis = (rideId, navigate) => {
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const requestUrl = 'http://localhost:3335/api/admin/evaluate-kpis-and-outcome/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/evaluate-kpis-and-outcome/' + rideId;
    return (dispatch) => {
        dispatch(startAnalysisRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            dispatch(startAnalysisSuccess(response.data))
            navigate(DO_ANALYSIS_URL + rideId)
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(startAnalysisFailure(errorMsg))
            //dispatch(handleDefaultError(navigate))
            navigate(ERROR_URL)
        })
    };
}

export const getKpisByRideId = (rideId, navigate) => {
    
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const requestUrl = API_BASE_URL + 'admin/kpi/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/kpi/' + rideId;
    return (dispatch) => {
        dispatch(getKpisRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const kpis = response.data.kpi
            dispatch(getKpisSuccess(kpis))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getKpisFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getOutcomeTextsByRideId = (rideId, navigate) => {
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    //const requestUrl = API_BASE_URL + 'admin/outcome-text/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/outcome-text/' + rideId;
    return (dispatch) => {
        dispatch(getOutcomeTextsRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const texts = response.data.texts
            dispatch(getOutcomeTextsSuccess(texts))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getOutcomeTextsFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getResultTextsByPathUrl = (pathUrl, navigate) => {
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'analysis/result-texts/' + pathUrl;
    return (dispatch) => {
        dispatch(getResultTextsRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM
                }
            }
        )
        .then(response => {
            const texts = response.data.resultTexts;
            const extraText = response.data.extraText;
            dispatch(getResultTextsSuccess(texts, extraText))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getResultTextsFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}


export const getAnalysisData = (rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'sensordata/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/corrected-sensordata/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    return (dispatch) => {
        dispatch(getAnalysisDataRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const sensorData = response.data.correctedSensorData;
            const startIndex = response.data.startIndex;
            const endIndex = response.data.endIndex;
            const index = {
                "startIndex": startIndex,
                "endIndex": endIndex 
            }
            dispatch(getAnalysisDataSuccess(sensorData, index))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getAnalysisDataFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getFilteredSensorData = (rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/sensordata-filtered/' + rideId;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/corrected-sensordata-filtered/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    return (dispatch) => {
        dispatch(getAnalysisDataRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const sensorData = response.data.correctedSensorData;
            const startIndex = response.data.startIndex;
            const endIndex = response.data.endIndex;
            const index = {
                "startIndex": startIndex,
                "endIndex": endIndex 
            }
            dispatch(getAnalysisDataSuccess(sensorData, index))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getAnalysisDataFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const getFilteredSensorDataByPathUrl = (pathUrl, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/sensordata-filtered/path/' + pathUrl;
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'analysis/corrected-sensordata-filtered/path/' + pathUrl;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    
    return (dispatch) => {
        dispatch(getAnalysisDataRequest)
        axios.get(
            requestUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM
                }
            }
        )
        .then(response => {
            dispatch(getAnalysisDataSuccess(response.data.correctedSensorData))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(getAnalysisDataFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const saveAnalysis = (request, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/save-analysis';
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/save-analysis';
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    return (dispatch) => {
        dispatch(saveAnalysisRequest)
        axios.post(
            requestUrl,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const rideId = responseData.rideId;
            dispatch(saveAnalysisSuccess(responseData))
            navigate(CONFIRMATION_PAGE_URL + rideId);
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(saveAnalysisFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const saveNotesForRide = (request, rideId, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/save-analysis';
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/save-notes/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
    return (dispatch) => {
        dispatch(saveNotesRequest)
        axios.post(
            requestUrl,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const ride = responseData.ride;
            dispatch(saveNotesSuccess(ride))
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(saveNotesFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}

export const saveCorrectSensorData = (rideId, request, navigate) => {
    //const requestUrl = API_BASE_URL + 'admin/save-analysis';
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/corrected-sensordata/save/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
     
    return (dispatch) => {
        dispatch(saveCorrectedSensorDataRequest)
        axios.post(
            requestUrl,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            dispatch(saveCorrectedSensorDataSuccess(responseData.success))
            dispatch({type: UTILS_ACTION_TYPES.RESET_RELOADING })
            //navigate(DO_ANALYSIS_URL + rideId)
        })
        .catch(error => {
            const errorMsg = error.message;
            dispatch(saveCorrectedSensorDataFailure(errorMsg))
            navigate(ERROR_URL)
        })
    };
}


export const archiveRideById = (rideId) => {
    //const requestUrl = API_BASE_URL + 'admin/save-analysis';
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'admin/rides/archive/' + rideId;
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const accessToken = currentUser.accessToken;
     
    return (dispatch) => {
        dispatch(archiveRideRequest)
        axios.post(
            requestUrl,
            null,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        )
        .then(response => {
            if(response.data && response.data.header && response.data.header.status) {
                dispatch(archiveRideSuccess(response.data.header.status))
            } else {
                //How to handle this?
                console.log("Couldn't find status from response");
            }
        })
        .catch(error => {
            let errorMsg = ''
            if(error.response && error.response.data && error.response.data.bluegrassStatus && error.response.data.bluegrassStatus.detail){
                // Request made and server responded
                errorMsg = error.response.data.bluegrassStatus.detail;
            } else if (error.request) {
                // The request was made but no response was received
                errorMsg = 'Serverfel, försök igen senare.'
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMsg = 'Något gick fel, försök igen senare'
            }
            dispatch(archiveRideFailure(errorMsg))
        })
    };
}



