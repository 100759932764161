import { Card, CardContent, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getAnalysisData, getKpisByRideId, getOutcomeTextsByRideId, getRideById, saveAnalysis, saveCorrectSensorData, startAnalysis } from '../../store/actions/RideActions';
import GraphGeneratorForAnalyze from '../GraphGeneratorForAnalyze';
import OutcomeTextsHandler from './../OutcomeTextsHandler';
import { ACTION_TYPES as USER_ACTION_TYPES } from '../../store/reducers/UserReducer';
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';
import { ACTION_TYPES as UTILS_ACTION_TYPES } from '../../store/reducers/UtilsReducer';
import { useBetween } from "use-between";
import { useShareableState } from '../utils/SharableStates';
import { getSensorDataAsCsv } from '../../store/actions/SensorDataActions';
import { getUserByRideId } from '../../store/actions/UserActions';
import { evaluateGaitTypeSwe, RISING_TROT, SITTING_TROT } from '../../utils/AppUtils';


const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            position: 'relative',
        },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        rideInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '18rem',
            margin: 'auto',
        },
        cardWrapper: {
            marginTop: '2.5rem',
            width: '100%',
        },
        cardContent: {
            padding: '1rem 0 2rem 0',
        },
        kpiAndGraphWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '5rem',
            '@media (min-width: 1068px)': {
                flexDirection: 'row',
            },
        },
        kpi: {
            border: 'solid lightGray 2px',
            borderRadius: 10,
            padding: '1rem 2rem',
            marginBottom: '2rem',
            marginRight: '1rem',
            marginLeft: '1rem',
            '@media (min-width: 1068px)': {
                marginTop: '17rem',
                 marginBottom: '0',
                 marginRight: '1rem',
                 width: '28%',
                 height: '30rem'
            },
        },
        graphWrapper: {
            width: '100%',
            '@media (min-width: 1068px)': {
                width: '67%',
           },
        },
        graphImage: {
            maxWidth: '50rem'
        },
        adjustingCheckboxContainer: {
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto'
        },
        checkboxWrapper: {
            padding: '1.5rem 2rem',
            display: 'flex',
            alignItems: 'center'
        },
        checkbox: {
            width: '1.4rem',
            height: '1.4rem'
        },
        labelAll: {
            color: 'rgb(245 179 53)',
            fontWeight: 'bold'
        },
        textX: {
            color: 'red',
            fontWeight: 'bold'
        },
        textY: {
            color: 'blue',
            fontWeight: 'bold'
        },
        textZ: {
            color: '#a6a6a6',
            fontWeight: 'bold'
        },
        switchingButtonContainer:{
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto'
        },
        buttonWrapper:{
			display: 'flex',
			justifyContent:'center',
			padding: '2rem 1rem'
	   },
       switchButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '4rem',
            border: '2px solid rgb(245 179 53)',
            borderRadius: '0.3rem',
            backgroundColor: 'transparent',
            fontWeight: 'bold',
            cursor: 'pointer',
            "&:hover": {
                color: 'rgb(245 179 53)!important'
            }
       },
	   button: {
            padding: '0.7rem 0.5rem',
            width: '9rem',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.975rem',
            borderRadius: '0.7rem',
            fontWeight: '500',
            cursor: 'pointer',
            border: 'none',
            marginLeft: '1rem',
            marginRight: '1rem',
            "&:hover": {
                color: '#a6a6a6'
            }
		}
    }
  })

function DoAnalysis({loading, getKpisByrideId, getOutcomeTextsByrideId, getAnalysisData, analysisData, kpis, outcomeTexts, saveAnalysis, sensorDataIndex, saveCorrectSensorData, success, getSensorDataAsCsv, csvFile, startAnalysis, reloading, getUserByRideId, user, getRideById, ride }) {
    const classes = useStyles();
    const {rideId} = useParams();
    const navigate = useNavigate();
    const [showAllData, setShowAllData] = useState(false);
    const [changeXdata, setChangeXData] = useState(false);
    const [changeYdata, setChangeYData] = useState(false);
    const [changeZdata, setChangeZData] = useState(false);
    const [switchXAndY, setSwitchXAndY] = useState(false);
    const [switchXAndZ, setSwitchXAndZ] = useState(false);
    const [switchYAndZ, setSwitchYAndZ] = useState(false);
    const dispatch = useDispatch();
    const { sensorData, setSensorData } = useBetween(useShareableState) 
    const [reset,setReset] = useState(false);


    useEffect(() => {
            getAnalysisData(rideId, navigate)
            getKpisByrideId(rideId, navigate)
            getOutcomeTextsByrideId(rideId, navigate)
            getUserByRideId(rideId, navigate);
            getRideById(rideId, navigate);
            

            return () => {
                dispatch({type: RIDE_ACTION_TYPES.CLEAR_ANALYSIS_DATA })
                dispatch({type: RIDE_ACTION_TYPES.CLEAR_KPIS })
                dispatch({type: RIDE_ACTION_TYPES.CLEAR_OUTCOME_TEXTS })
                dispatch({type: USER_ACTION_TYPES.CLEAR_USER })
                dispatch({type: RIDE_ACTION_TYPES.CLEAR_RIDE })
            }
    },[]);

    useEffect(() => {
        if(!reloading){
            getAnalysisData(rideId, navigate)
            getKpisByrideId(rideId, navigate)
            getOutcomeTextsByrideId(rideId, navigate)       
        }
    },[reloading]);

    const handleCheckboxChecked = (e) => {
        setReset(false)
        const targetName = e.target.name;
        if(targetName === 'showAll'){
            setShowAllData(!showAllData);
        }
        if(targetName === 'changeX'){
            setChangeXData(!changeXdata);
        }
        if(targetName === 'changeY'){
            setChangeYData(!changeYdata);
        }
        if(targetName === 'changeZ'){
            setChangeZData(!changeZdata);
        }
    }

    const handleSwapClick = (e) => {
        setReset(false)
        const targetName = e.target.name;
        if(targetName === 'x-to-y'){
            setSwitchXAndY(!switchXAndY)
        }
        if(targetName === 'x-to-z'){
            setSwitchXAndZ(!switchXAndZ)
        }
        if(targetName === 'y-to-z'){
            setSwitchYAndZ(!switchYAndZ)
        }
    }
 
    const handleSaveChanges = () => {
        setReset(false)
        setShowAllData(false)
        const requestBody = {
            sensorDataList: sensorData
        }
        saveCorrectSensorData(rideId, requestBody, navigate)
        dispatch({type: UTILS_ACTION_TYPES.START_RELOADING })
        dispatch({type: RIDE_ACTION_TYPES.CLEAR_ANALYSIS_DATA })
        dispatch({type: RIDE_ACTION_TYPES.CLEAR_KPIS })
        dispatch({type: RIDE_ACTION_TYPES.CLEAR_OUTCOME_TEXTS })
    
    }

    const handleResetChanges = (e) => {
        setReset(true)
        setSensorData(analysisData)
        document.getElementById('changeX').checked = false;
        document.getElementById('changeY').checked = false;
        document.getElementById('changeZ').checked = false;

        setChangeXData(false)
        setChangeYData(false)
        setChangeZData(false);
        
    }

    const handleFileDownload = () => {
        getSensorDataAsCsv(rideId, navigate)
    }
     
    return (
        
        <div className={classes.root}>
            {!loading && !reloading && analysisData.length && kpis.length && outcomeTexts.length && user && ride ?
                <div className={classes.content}>
                    <div>
                        <Typography variant='h1' align='center'>Rider's Positions analyseringsverktyg</Typography>
                        <Typography gutterBottom variant='body1' align='center'>Tryck på "Spara analys" längst ner på sidan när du känner dig nöjd med resultatet.</Typography>
                        <div className={classes.rideInfoContainer}>
                            <Typography variant='body1'><strong>Ryttare:</strong> &nbsp;{user.userName}</Typography>
                            <Typography variant='body1'><strong>Gångart:</strong> &nbsp;{evaluateGaitTypeSwe(ride.rideGaitType)}</Typography>
                            <Typography variant='body1'><strong>Språk:</strong> &nbsp;{ride.locale}</Typography>
                            
                        </div>
                    </div>
                    <Card elevation={0} className={classes.cardWrapper}>
                        <CardContent className={classes.cardContent}>
                            <div className={classes.kpiAndGraphWrapper}>
                                    <div className={classes.kpi}>
                                        <Typography gutterBottom variant='h6'>KPI:er</Typography>
                                        <div>
                                            {kpis && kpis.map((kpi,index) => {
                                                return (
                                                    <Typography variant='body1' gutterBottom key={index}><strong>{kpi.kpiName}:</strong> {kpi.kpiValue.toFixed(2)}</Typography>
                                                )
                                            })} 
                                        </div>
                                    </div>
                                <div className={classes.graphWrapper}>
                                    
                                    <div className={classes.adjustingCheckboxContainer}>
                                        <div className={classes.checkboxWrapper}>
                                            <input className={classes.checkbox} type="checkbox" name="showAll" onChange={(e) => handleCheckboxChecked(e)} />
                                            <label className={classes.labelAll} htmlFor="showAll">Se all data</label>
                                        </div>
                                        <div className={classes.checkboxWrapper}>
                                            <input className={classes.checkbox} type="checkbox" name="changeX" id='changeX' onChange={(e) => handleCheckboxChecked(e)} />
                                            <label className={classes.textX} htmlFor="changeX">Justera X</label>
                                        </div>
                                        <div className={classes.checkboxWrapper}>
                                            <input className={classes.checkbox} type="checkbox" name="changeY" id='changeY' onChange={(e) => handleCheckboxChecked(e)} />
                                            <label className={classes.textY}  htmlFor="changeY">Justera Y</label>
                                        </div>
                                        <div className={classes.checkboxWrapper}>
                                            <input className={classes.checkbox} type="checkbox" name="changeZ" id='changeZ' onChange={(e) => handleCheckboxChecked(e)} />
                                            <label className={classes.textZ} htmlFor="changeZ">Justera Z</label>
                                        </div>
                                    </div>
                                    <div className={classes.switchingButtonContainer}>
                                        <div className={classes.buttonWrapper}>
                                            <button className={classes.switchButton} type="button" name="x-to-y" onClick={(e) => handleSwapClick(e)}>
                                                X &#8646; Y
                                            </button>
                                        </div>
                                        <div className={classes.buttonWrapper}>
                                            <button className={classes.switchButton} type="button" name="x-to-z" onClick={(e) => handleSwapClick(e)}>
                                                X &#8646; Z
                                            </button>
                                        </div>
                                        <div className={classes.buttonWrapper}>
                                            <button className={classes.switchButton} type="button" name="y-to-z" onClick={(e) => handleSwapClick(e)}>
                                                Y &#8646; Z
                                            </button>
                                        </div>
                                    </div>
                                    <div className={classes.buttonWrapper}>
				                        <button onClick={() => handleSaveChanges()}  className={classes.button}>Spara ändringar</button>
                                        <button onClick={(e) => handleResetChanges(e)}  className={classes.button}>Återställ ändringar</button>
                                        <button onClick={() => handleFileDownload()} className={classes.button}>Hämta CSV-fil</button>
			                        </div>
                                    <GraphGeneratorForAnalyze reset={reset} showAllData={showAllData} changeXdata={changeXdata} changeYdata={changeYdata} changeZdata={changeZdata} switchXAndY={switchXAndY} switchXAndZ={switchXAndZ} switchYAndZ={switchYAndZ} graphData={analysisData} sensorDataIndex={sensorDataIndex}/>
                                </div>
                            </div>
                            <OutcomeTextsHandler outcomeTexts={outcomeTexts} saveAnalysis={saveAnalysis}/>
                        </CardContent>
                    </Card>
                </div>
            : 
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
            
        </div>
    )

    
}

const mapStateToProps = ({ ride, sensorData, utils, user }) => ({
    loading: ride.loading,
    analysisData: ride.analysisData,
    kpis: ride.kpis,
    response: ride.response,
    success: ride.success,
    outcomeTexts: ride.outcomeTexts,
    sensorDataIndex: ride.sensorDataIndex,
    csvFile: sensorData.csvFile,
    reloading: utils.reloading,
    user: user.user,
    ride: ride.ride
})

const mapDispatchToProps = (dispatch, navigate) => {
    return {
        getKpisByrideId: (rideId, navigate) => dispatch(getKpisByRideId(rideId, navigate)),
        getOutcomeTextsByrideId: (rideId, navigate) => dispatch(getOutcomeTextsByRideId(rideId, navigate)),
        getAnalysisData: (rideId, navigate) => dispatch(getAnalysisData(rideId, navigate)),
        saveAnalysis: (request,navigate) => dispatch(saveAnalysis(request,navigate)),
        saveCorrectSensorData: (rideId, request, navigate) => dispatch(saveCorrectSensorData(rideId, request, navigate)),
        getSensorDataAsCsv: (rideId, navigate) => dispatch(getSensorDataAsCsv(rideId, navigate)),
        getUserByRideId: (rideId, navigate) => dispatch(getUserByRideId(rideId, navigate)),
        startAnalysis: (rideId, navigate) => dispatch(startAnalysis(rideId, navigate)),
        getRideById: (rideId, navigate) => dispatch(getRideById(rideId, navigate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoAnalysis);