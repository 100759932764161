import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { login } from "../../store/actions/AuthActions";
import { LOGO_URL, TODO_ANALYZES_URL } from "../../utils/AppUtils";
import { Navigate} from "react-router-dom";
import { useNavigate } from 'react-router';
import { styled } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { ACTION_TYPES } from "../../store/reducers/AuthReducer";
import rpLogoSmall from '../../assets/rp-logo.webp';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        card: {
            padding: '1.5rem 0',
            marginTop: '2.5rem',
            width: '25rem',
            maxWidth: '90%',
            borderRadius: 8,
            boxShadow: '0 1px 1px 0 rgb(0 0 0/0.1),0 1px 1px 2.5px rgb(0 0 0/0.07)',
        },
        cardContent: {
            width: '85%',
            margin: 'auto',
            padding: 0,
            '&:last-child': {
                paddingBottom: 0
            },
        },
        buttonWrapper: {
            display:'flex',
            justifyContent: 'center',
            marginBottom: 10,
            marginTop: 10
        },
        inputField: {
            
        },
        button: {
            marginTop: '1rem',
            width: '100%',
            padding: '1rem 0.75rem',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.775rem',
            borderRadius: '0.8rem',
            fontWeight: '500',
            cursor: 'pointer',
            border: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (min-width: 375px)': {
                fontSize: '0.975rem',
           },
        }
    }
  })

  const StyledTextField = styled(TextField)({
    '& .MuiInputLabel-root': {
        top: '2px'
      },
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 10,
        padding: '2px 2px',
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(245 179 53)',
      },
    },
  });

function Login({loading, login, errorMessage, isAuthenticated, isAdmin}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [errorMsg,setErrorMsg] = useState('');
    const [formValue, setFormValue] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({ type: ACTION_TYPES.SET_ERROR_MESSAGE_TO_NULL}) 
        const loginRequest = {
            'email': formValue.email,
            'password': formValue.password
        }
        login(loginRequest, navigate);
    }

    const handleChange = (event) => {
        setErrorMsg('')
        setFormValue({
          ...formValue,
          [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        setErrorMsg(errorMessage)
    }, [errorMessage]);
    

    if (isAuthenticated) {
        return <Navigate to={TODO_ANALYZES_URL} />;
    }

    
    return (
        
        <div className={classes.root}>
            <div className={classes.imageWrapper}>
                <img 
                    className={classes.logo} 
                    alt="logo" 
                    src={rpLogoSmall}
                />
            </div>
            <div>
                <Typography variant='h1' align='center'>Logga in på ditt konto</Typography>
            </div>
            <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <form onSubmit={handleSubmit}>
                        <StyledTextField
                            type="email"
                            name="email"
                            required
                            id="email"
                            label="E-postadress"
                            className={classes.inputField}
                            margin="normal"
                            fullWidth
                            value={formValue.email}
                            placeholder="Ange din e-postadress..."
                            onChange={handleChange}
                        />
                        <StyledTextField
                            type="password"
                            name="password"
                            required
                            id="password"
                            label="Lösenord"
                            className={classes.inputField}
                            margin="normal"
                            fullWidth
                            value={formValue.password}
                            placeholder="Ange ditt lösenord..."
                            onChange={handleChange}
                        />
                        <div className={classes.buttonWrapper}>
                            <button type="submit" className={classes.button}>
                                <LockIcon />&nbsp;Logga in med e-postadress
                            </button>
                        </div>
                        {errorMsg ? <Typography align="center" color="error" variant="body2">{errorMsg}</Typography> : null}
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

const mapStateToProps = ({ auth, isAdmin }) => ({
    loading: auth.loading,
    errorMessage: auth.errorMessage,
    isAuthenticated: auth.isAuthenticated,
    isAdmin: auth.isAdmin
})

const mapDispatchToProps = (dispatch) => {
    return {
        login: (loginRequest, navigate) => dispatch(login(loginRequest, navigate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);