import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils";

export const ACTION_TYPES = {
    FETCH_RIDE_BY_ID: 'FETCH_RIDE_BY_ID',
    FETCH_RIDE_BY_PATH_URL: 'FETCH_RIDE_BY_PATH_URL',
    FETCH_RIDES_IN_PROCESS: 'FETCH_RIDES_IN_PROCESS',
    FETCH_RIDES_COMPLETED: 'FETCH_RIDES_COMPLETED',
    FETCH_RIDES_NOT_PURCHASED: 'FETCH_RIDES_NOT_PURCHASED',
    START_ANALYSIS: 'START_ANALYSIS',
    FETCH_KPIS_BY_RIDE_ID: 'FETCH_KPIS_BY_RIDE_ID',
    FETCH_OUTCOME_TEXTS_BY_RIDE_ID: 'FETCH_OUTCOME_TEXTS_BY_RIDE_ID',
    FETCH_RESULT_TEXTS_BY_PATH_URL: 'FETCH_RESULT_TEXTS_BY_PATH_URL',
    FETCH_ANALYSIS_DATA: 'FETCH_ANALYSIS_DATA',
    SAVE_FINISHED_ANALYSIS: 'SAVE_FINISHED_ANALYSIS',
    ARCHIVE_RIDE_BY_ID: 'ARCHIVE_RIDE_BY_ID', 
    CLEAR_RIDE: 'CLEAR_RIDE',
    CLEAR_RIDES: 'CLEAR_RIDES',
    CLEAR_ANALYSIS_DATA: 'CLEAR_ANALYSIS_DATA',
    CLEAR_KPIS: 'CLEAR_KPIS',
    CLEAR_OUTCOME_TEXTS: 'CLEAR_OUTCOME_TEXTS',
    CLEAR_RESULT_TEXTS: 'CLEAR_RESULT_TEXTS',
    CLEAR_RESPONSE: 'CLEAR_RESPONSE',
    CLEAR_TOTAL_NUMBER_OF_RIDES: 'CLEAR_TOTAL_NUMBER_OF_RIDES',
    SAVE_NOTES_FOR_RIDE: 'SAVE_NOTES_FOR_RIDE',
    SAVE_CORRECTED_SENSOR_DATA: 'SAVE_CORRECTED_SENSOR_DATA',
    SET_RERENDER_AS_FALSE: 'SET_RERENDER_AS_FALSE',
    SET_ERROR_MESSAGE_TO_NULL: 'SET_ERROR_MESSAGE_TO_NULL',
    SET_LOADING_AS_TRUE: 'SET_LOADING_AS_TRUE',
    SET_STATUS_TO_NULL: 'SET_STATUS_TO_NULL',
}

const initState = {
    loading: false,
    errorMessage: null,
    ridesInProcess: [],
    ridesCompleted: [],
    ridesNotPurchased: [],
    totalNumOfRides: null,
    ride: null,
    response: '',
    startedAnalysis: false,
    kpis: [],
    outcomeTexts: [],
    resultTexts: [],
    extraText: '',
    analysisData: [],
    success: null,
    sensorDataIndex: null,
    status: null,
    reRender: false
};

const RideReducer = (state = initState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SET_STATUS_TO_NULL:
            return {
                ...state,
                status: null
            }
        case ACTION_TYPES.SET_LOADING_AS_TRUE:
            return {
                ...state,
                loading: true 
            }
        case ACTION_TYPES.SET_ERROR_MESSAGE_TO_NULL: 
            return {
                ...state,
                errorMessage: null
            }    
        case ACTION_TYPES.CLEAR_RIDE:
            return {
                ...state,
                ride: null
            };
        case ACTION_TYPES.CLEAR_RIDES:
            return {
                ...state,
                ridesInProcess: [],
                ridesCompleted: [],
                ridesNotPurchased: []
            };
        case ACTION_TYPES.CLEAR_ANALYSIS_DATA:
            return {
                ...state,
                analysisData: []
            };
        case ACTION_TYPES.CLEAR_KPIS:
            return {
                ...state,
                kpis: []
            };
        case ACTION_TYPES.CLEAR_OUTCOME_TEXTS:
            return {
                ...state,
                outcomeTexts: []
            };
        case ACTION_TYPES.CLEAR_RESULT_TEXTS:
            return {
                ...state,
                resultTexts: []
            };
        case ACTION_TYPES.CLEAR_RESPONSE:
            return {
                ...state,
                response: ''
            };
        case ACTION_TYPES.SET_RERENDER_AS_FALSE:
            return {
                ...state,
                reRender: false
            };
        case ACTION_TYPES.CLEAR_TOTAL_NUMBER_OF_RIDES:
            return {
                ...state,
                totalNumOfRides: null
            };
        case REQUEST(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA):
            return {
                ...state,
                errorMessage: null,
                loading: true,
                success: null
            };
        case REQUEST(ACTION_TYPES.FETCH_RIDE_BY_ID):
        case REQUEST(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL):
        case REQUEST(ACTION_TYPES.FETCH_RIDES_IN_PROCESS):
        case REQUEST(ACTION_TYPES.FETCH_RIDES_COMPLETED):
        case REQUEST(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED):
        case REQUEST(ACTION_TYPES.START_ANALYSIS):
        case REQUEST(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID):
        case REQUEST(ACTION_TYPES.FETCH_ANALYSIS_DATA):
        case REQUEST(ACTION_TYPES.SAVE_FINISHED_ANALYSIS):
        case REQUEST(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID):
        case REQUEST(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL):
        case REQUEST(ACTION_TYPES.SAVE_NOTES_FOR_RIDE):
            return {
                ...state,
                errorMessage: null,
                loading: true
            };
        case REQUEST(ACTION_TYPES.ARCHIVE_RIDE_BY_ID):
            return {
                ...state,
                errorMessage: null,
                loading: true,
                status: null
            };
        case FAILURE(ACTION_TYPES.FETCH_RIDE_BY_ID):
        case FAILURE(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL):
        case FAILURE(ACTION_TYPES.FETCH_RIDES_IN_PROCESS):
        case FAILURE(ACTION_TYPES.FETCH_RIDES_COMPLETED):
        case FAILURE(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED):
        case FAILURE(ACTION_TYPES.START_ANALYSIS):
        case FAILURE(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID):
        case FAILURE(ACTION_TYPES.FETCH_ANALYSIS_DATA):
        case FAILURE(ACTION_TYPES.SAVE_FINISHED_ANALYSIS):
        case FAILURE(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID):
        case FAILURE(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL):
        case FAILURE(ACTION_TYPES.SAVE_NOTES_FOR_RIDE):
        case FAILURE(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA):
        case FAILURE(ACTION_TYPES.ARCHIVE_RIDE_BY_ID):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        case SUCCESS(ACTION_TYPES.FETCH_RIDE_BY_ID):
        case SUCCESS(ACTION_TYPES.FETCH_RIDE_BY_PATH_URL):
        case SUCCESS(ACTION_TYPES.SAVE_NOTES_FOR_RIDE):
            return {
                ...state,
                loading: false,
                ride: action.payload
            };
        case SUCCESS(ACTION_TYPES.FETCH_RIDES_IN_PROCESS):
            return {
                ...state,
                loading: false,
                ridesInProcess: action.rides,
                totalNumOfRides: action.numOfRides,
            };
        case SUCCESS(ACTION_TYPES.FETCH_RIDES_COMPLETED):
            return {
                ...state,
                loading: false,
                ridesCompleted: action.rides,
                totalNumOfRides: action.numOfRides
            };
        case SUCCESS(ACTION_TYPES.FETCH_RIDES_NOT_PURCHASED):
            return {
                ...state,
                loading: false,
                ridesNotPurchased: action.rides,
                totalNumOfRides: action.numOfRides
            };
        case SUCCESS(ACTION_TYPES.START_ANALYSIS):
        case SUCCESS(ACTION_TYPES.SAVE_CORRECTED_SENSOR_DATA):
            return {
                ...state,
                loading: false,
                success: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_KPIS_BY_RIDE_ID):
            return {
                ...state,
                loading: false,
                kpis: action.kpis,
            };
        case SUCCESS(ACTION_TYPES.FETCH_OUTCOME_TEXTS_BY_RIDE_ID):
            return {
                ...state,
                loading: false,
                outcomeTexts: action.outcomeTexts,
            };
        case SUCCESS(ACTION_TYPES.FETCH_RESULT_TEXTS_BY_PATH_URL):
            return {
                ...state,
                loading: false,
                resultTexts: action.resultTexts,
                extraText: action.extraText
            };
        case SUCCESS(ACTION_TYPES.FETCH_ANALYSIS_DATA):
            return {
                ...state,
                loading: false,
                analysisData: action.analysisData,
                sensorDataIndex: action.index
            };
        case SUCCESS(ACTION_TYPES.SAVE_FINISHED_ANALYSIS):
            return {
                ...state,
                loading: false,
                response: action.response
            };
        case SUCCESS(ACTION_TYPES.ARCHIVE_RIDE_BY_ID):
            return {
                ...state,
                loading: false,
                status: action.status,
                reRender: true,
                errorMessage: null
            };
        default: 
            return state;
    }
}

export default RideReducer;