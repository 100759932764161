import { Typography, makeStyles } from "@material-ui/core"
import GraphGeneratorForCustomer from "./GraphGeneratorForCustomer";
import { RISING_TROT, evaluateGaitTypeEng} from "../utils/AppUtils";
import risingTrotProGraphEng from '../assets/proffsgraf-lättridning-1440-eng.png'
import sittingTrotProGraphEng from '../assets/proffsgraf-trav-nedsittning-eng.png'
import riderImage from '../assets/Ryttarbild.png';
import mariSignature from '../assets/maris-signatur-edit.jpg'

const useStyles = makeStyles((theme) => {
    return {
        contentText: {
            fontSize: '1.2rem',
            color: '#6B6B6B',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        blackText: {
            fontSize: '1.2rem',
            color: 'black',
            fontWeight: '300',
            marginTop: '0.8rem',
        },
        riderImageWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                justifyContent: 'start',
                maxWidth: '50rem'
            }
        },
        riderImage: {
            width: '300px',
            maxWidth: '95%'
        },
        graphWrapper: {
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2rem auto',
            '@media (min-width: 499px)': {
                maxWidth: '90%'
            },
            '@media (min-width: 1068px)': {
                maxWidth: '50rem'
            },
        },
        extraTextContainer: {
            wordBreak: 'break-word'
        },
        proGraph: {
            maxWidth: '95%'
        },
        signatureContainer: {
            marginTop: '0.6rem',
            
            display: 'flex',
            justifyContent: 'center'
        }
}})

function EnglishCompletedAnalysisContent({user, ride, analysisData,resultTexts,extraText}) {
    const classes = useStyles();

    const handleExtraText = () => {

        //Make sure that pastes links becames a html link
        let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

        return extraText.replace(urlRegex, function (url) {
            return '<br><a href="' + url + '">' + url + '</a><br>';
        })

    }

    return (
        <div>
        <Typography variant="body1" className={classes.blackText}>
            <strong>Hi {user.userName},</strong>
        </Typography>
        <Typography variant="body1" className={classes.contentText}>
        It's great that you want to become more aware of your position in the saddle during riding so you can 
        improve the interaction between you and your horse. As you probably know, a good position helps the horse to work
         correctly and build the muscles needed to support the rider. In addition, a good position is a prerequisite for you to be able 
         to communicate successfully with the horse.
        </Typography>
        <Typography variant="body1" className={classes.contentText}>
        The rider has a great influence on the horse's health and well-being. A well-balanced and flexible seat is a prerequisite for you
         to "swing with the horse" and create a joint movement with the horse without disturbing it.
        </Typography>
        <Typography variant="body1" className={classes.contentText}>
        In the Rider Analysis we measure how you move up and down and back and forth at the trot (see sketch), and how smooth your movements are. 
        </Typography>
        <Typography variant="body1" className={classes.contentText}>
        In trot the horse is constantly alternating between accelerating and decelerating in each trot step and therefore it is challenging for 
        the rider to be steady yet supple. 
        </Typography>
        <div className={classes.riderImageWrapper}>
            <img
                src={riderImage}
                alt="rider"
                className={classes.riderImage}
            />
        </div>
        <Typography variant="body1" className={classes.blackText}>
            <strong><i>We have now analyzed your movement data during riding and the analysis shows/says: </i></strong>
        </Typography>
        <Typography variant="body1" className={classes.blackText}>
            <strong>This is what your movements (graphs) look like. </strong>
        </Typography>
        
        <Typography variant="body1" className={classes.contentText}>
        Here you can see how you move in {evaluateGaitTypeEng(ride.rideGaitType)}. The red curve shows your movement up and down and the grey your movement back and forth in the saddle. 
        </Typography>
        <div className={classes.graphWrapper}>
            <GraphGeneratorForCustomer locale={ride.locale} graphData={analysisData} />
        </div>
        {resultTexts && resultTexts.map((text, index) => {
            return (
                <Typography key={index} variant="body1" className={classes.contentText}>{text.text}</Typography>
            )
        })}

        {extraText !== null && extraText !== '' && extraText !== undefined ?
            <div className={classes.extraTextContainer}>
                <Typography dangerouslySetInnerHTML={{ __html: handleExtraText(extraText) }} variant="body1" className={classes.contentText} />
            </div>
            : null
        }
        <br/>
        <Typography variant="body1" className={classes.blackText}>
            <strong>Compare yourself to the elite rider!</strong>
        </Typography>
        
        <Typography variant="body1" className={classes.contentText}>
        This is what it looks like when an elite rider rides. The rider absorbs the up and down motion with their hip, knee, ankle, 
        and lower back. Therefore, the red curves are soft and wavy and not so high, which contributes to less strain on the horse's back.
        </Typography>
        <Typography variant="body1" className={classes.contentText}>
        This rider moves smoothly back and forth in the saddle and follows the horse's forward movement well. Therefore, the grey curves move 
        evenly around the 0-line. This is because the rider has a strong
            core and is flexible in her hips. The rider is also smooth in her movements which makes it easier for the horse to move in an even rhythm and in 
            balance.  
        </Typography>
        
        <div className={classes.graphWrapper}>
            {ride.rideGaitType === RISING_TROT ?

                //Graph for rising trot swe version
                <>
                    <img
                    src={risingTrotProGraphEng}
                    alt="graph"
                    className={classes.proGraph}
                    />
                </>
                
                :
        
                //Graph for sitting trot swe version
                <>
                    <img
                    src={sittingTrotProGraphEng}
                    alt="graph"
                    className={classes.proGraph}
                    />
                </>   
            }
        </div>
        <Typography variant="body1" className={classes.blackText}>
            <strong>If you want to read more about the rider's movements in the saddle and the measurement, click <a href="https://ridersposition.com/rider-analysis-for-those-who-want-to-know-more/">here!</a></strong>
        </Typography>
        <br/>
        <br/>
        <Typography align="left" variant="body1" className={classes.blackText}>
            <strong>Good luck with your riding and training! If you have questions send a mail to <a href="mailto:info@ridersposition.com">info@ridersposition.com</a> </strong>
        </Typography>
        <div className={classes.signatureContainer}>
            <img
                src={mariSignature}
                alt="maris signatur"
            />
        </div>
    </div>
    )

}

export default EnglishCompletedAnalysisContent;