import axios from "axios"
import { v4 as uuid4 } from "uuid";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils"
import { BG_SENDER_SYSTEM, CURRENT_USER, ERROR_URL} from "../../utils/AppUtils"
import { ACTION_TYPES } from "../reducers/AuthReducer"

const loginRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.LOGIN)
    }
}

const loginSuccess = (currentUser) => {
    return {
        type: SUCCESS(ACTION_TYPES.LOGIN),
        currentUser: currentUser
    }
}

const loginFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.LOGIN),
        payload: error
    }
}

const logoutRequest = () => {
    return {
        type: REQUEST(ACTION_TYPES.LOGOUT)
    }
}

const logoutSuccess = () => {
    return {
        type: SUCCESS(ACTION_TYPES.LOGOUT),
    }
}

const logoutFailure = (error) => {
    return {
        type: FAILURE(ACTION_TYPES.LOGOUT),
        payload: error
    }
}

export const login = (loginRequestBody) => {
    const requestUrl = process.env.REACT_APP_API_BASE_URL + 'auth/admin-login';
    //const requestUrl = API_BASE_URL + 'auth/admin-login';
    const breadcrumbId = uuid4();
    const timestamp = Date.now();
    return (dispatch) => {
        dispatch(loginRequest)
        axios.post(
            requestUrl,
            loginRequestBody,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'breadcrumbId': breadcrumbId,
                    'bg-timestamp': timestamp,
                    'bg-senderSystem': BG_SENDER_SYSTEM,
                }
            }
        )
        .then(response => {
            const responseData = response.data;
            const accessToken = responseData.accessToken;
            const role = responseData.role;
            const currentUser = {
                "accessToken": accessToken,
                "role": role
            }
            //localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
            dispatch(loginSuccess(currentUser));
        })
        .catch(error => {
            let errorMsg = ''
            if(error.response && error.response.data && error.response.data.bluegrassStatus && error.response.data.bluegrassStatus.detail){
                // Request made and server responded
                errorMsg = error.response.data.bluegrassStatus.detail;
            } else if (error.request) {
                // The request was made but no response was received
                errorMsg = 'Serverfel, försök igen senare.'
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMsg = 'Något gick fel vid inlogg, försök igen senare'
            }
            dispatch(loginFailure(errorMsg))
        })
    };
}

export const logout = (navigate) => {
    return (dispatch) => {
        dispatch(logoutRequest)
        
        try {
            localStorage.removeItem(CURRENT_USER);
            dispatch(logoutSuccess());
        } catch {
            const errorMsg = 'Något gick fel vid utloggning.';
            dispatch(logoutFailure(errorMsg))
            navigate(ERROR_URL)
        }
    
    };
}
