import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { Line, Chart as Chart2 } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useShareableState } from "./utils/SharableStates";
import { useBetween } from "use-between";

Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => {
    return {
       container: {
			width: '95%',
			margin: 'auto',
			padding: '0.5rem',
			backgroundColor: 'white',
	   },
	   range: {
			marginTop: '1rem'
	   },
	   indexText: {
			padding: '0.2rem 0.5rem',
			color: 'red'
	   },
	   indexContainer: {
			display:'flex',
			justifyContent: 'center'
	   },
	   buttonWrapper:{
			display: 'flex',
			justifyContent:'center',
			padding: '2rem 0'
	   },
	   button: {
		padding: '0.7rem 1.5rem',
		color: 'white',
		backgroundColor: 'rgb(245 179 53)',
		textDecoration: 'none',
		fontSize: '.975rem',
		borderRadius: '0.7rem',
		fontWeight: '500',
		cursor: 'pointer',
		border: 'none',
		"&:hover": {
			color: '#a6a6a6'
		}
		}
}})


function GraphGeneratorForAnalyze({ reset, graphData, showAllData, changeZdata, changeXdata, changeYdata, switchXAndY, switchXAndZ, switchYAndZ, sensorDataIndex }) {
	const classes = useStyles();
	const [labels, setLabels] = useState([]);
    const [accX, setAccX] = useState([]);
    const [accY, setAccY] = useState([]);
    const [accZ, setAccZ] = useState([]);
	const [changeX, setChangeX] = useState(changeXdata);
    const [changeY, setChangeY] = useState(changeYdata);
    const [changeZ, setChangeZ] = useState(changeZdata);
	const [swapXAndY, setSwapXAndY] = useState(switchXAndY);
	const [swapXAndZ, setSwapXAndZ] = useState(switchXAndZ);
	const [swapYAndZ, setSwapYAndZ] = useState(switchYAndZ);
	const { sensorData, setSensorData } = useBetween(useShareableState)

	function setXYZ(sensorData) {
		let tempX = [];
		let tempY = [];
		let tempZ = [];
		let tempLabels = [];

		for (let i = 0; i < sensorData.length; i++) {
			tempX.push(sensorData[i].accX);
			tempY.push(sensorData[i].accY);
			tempZ.push(sensorData[i].accZ);
			tempLabels.push(i);
		}
		setAccX(tempX);
		setAccY(tempY);
		setAccZ(tempZ);
		setLabels(tempLabels)
	}

	function handleChangeX() {
		if(changeX !== changeXdata){
			

			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970;
				const newXValue = sensorData[i].accX * (-1);
				const accY = sensorData[i].accY
				const accZ = sensorData[i].accZ

				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: newXValue,
					accY: accY,
					accZ: accZ
				}
				tempSensorData.push(updatedData);
				
			}
			setSensorData(tempSensorData)

			setChangeX(!changeX);
			
		}
	}

	function handleChangeY(){
		if(changeY !== changeYdata){
			
			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970
				const newYValue = sensorData[i].accY * (-1);
				const accZ = sensorData[i].accZ
				const accX = sensorData[i].accX

				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: accX,
					accY: newYValue,
					accZ: accZ
				}
				tempSensorData.push(updatedData);
				
			}
			setSensorData(tempSensorData)

			setChangeY(!changeY);
		}
	}

	function handleChangeZ(){
		if(changeZ !== changeZdata){
			
			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970
				const newZValue = sensorData[i].accZ * (-1);
				const accY = sensorData[i].accY
				const accX = sensorData[i].accX

				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: accX,
					accY: accY,
					accZ: newZValue
				}
				tempSensorData.push(updatedData);
				
			}
			setSensorData(tempSensorData)

			setChangeZ(!changeZ);
		}
	}

	function handleSwapXAndY(){
		
		if(swapXAndY !== switchXAndY){
			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970
				const accZ = sensorData[i].accZ;
				const accY = sensorData[i].accY;
				const accX = sensorData[i].accX;

				//set accX to accY and vice versa
				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: accY,
					accY: accX,
					accZ: accZ
				}
				tempSensorData.push(updatedData);
			}
			setSensorData(tempSensorData)

			setSwapXAndY(!swapXAndY);
		}
	}

	function handleSwapXAndZ() {

		if(swapXAndZ !== switchXAndZ){
			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970
				const accZ = sensorData[i].accZ;
				const accY = sensorData[i].accY;
				const accX = sensorData[i].accX;

				//set accX to accZ and vice versa
				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: accZ,
					accY: accY,
					accZ: accX
				}
				tempSensorData.push(updatedData);
			}
			setSensorData(tempSensorData)

			setSwapXAndZ(!swapXAndZ);
		}

	}
	
	function handleSwapYAndZ() {
		
		if(swapYAndZ !== switchYAndZ){
			let tempSensorData = [];
			for (let i = 0; i < sensorData.length; i++) {
				const id = sensorData[i].id;
				const timeInterval = sensorData[i].timeIntervalSince1970
				const accZ = sensorData[i].accZ;
				const accY = sensorData[i].accY;
				const accX = sensorData[i].accX;

				//set accY to accZ and vice versa
				const updatedData = {
					id: id,
					timeIntervalSince1970: timeInterval,
					accX: accX,
					accY: accZ,
					accZ: accY
				}
				tempSensorData.push(updatedData);
			}
			setSensorData(tempSensorData)

			setSwapYAndZ(!swapYAndZ);
		}
	}

	useEffect(() => {
		setSensorData(graphData)
	}, []);

	useEffect(() => {
		if(!reset){
			handleChangeX()
			handleChangeY()
			handleChangeZ()
			handleSwapXAndY()
			handleSwapXAndZ()
			handleSwapYAndZ()
		} else {
			setChangeX(false)
			setChangeY(false)
			setChangeZ(false)
		}
		
	}, [changeXdata, changeYdata, changeZdata, switchXAndY, switchXAndZ, switchYAndZ]);

	useEffect(() => {

		if (sensorData?.length > 0 ) {

			if(!showAllData && sensorDataIndex) {
				const startIndex = sensorDataIndex.startIndex;
				const endIndex = sensorDataIndex.endIndex;

				const filteredGraphData = sensorData.map((element, index) => index >= startIndex && index < endIndex ? element : undefined).filter(x => x);
				setXYZ(filteredGraphData);
			} else {
				setXYZ(sensorData);
			}
			
		} 
	}, [sensorData, showAllData]);

	const data = {
		labels: labels,
		datasets: [

			{
				label: "X(Sida till sida)",
				fill: false,
				lineTension: 0.1,
				backgroundColor: "#2BC628",
				borderWidth: 1.8,
				borderColor: "#2BC628",
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointRadius:0,
				data: accX,
			},
            {
				label: "Y(Upp och ned)",
				fill: false,
				lineTension: 0.1,
				backgroundColor: "#E85B0A",
				borderWidth: 1.8,
				borderColor: "#E85B0A",
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointRadius: 0,
				data: accY,
			},
            {
				label: "Z(Fram och tillbaka)",
				fill: false,
				lineTension: 0.1,
				backgroundColor: "#766e632",
				borderColor: "#766e632",
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderWidth: 1.8,
				borderJoinStyle: "miter",
				pointRadius: 0,
				data: accZ,
			},
		],
	};

	const zoomOptions = {
		limits: {
		  x: {min: 0, max: 5050, minRange: 50},
		  y: {min: -100, max: 100, minRange: 120}
		},
		pan: {
		  enabled: true,
		  mode: 'xy',
		},
		zoom: {
		  wheel: {
			enabled: true,
		  },
		  pinch: {
			enabled: true
		  },
		  mode: 'x',
		  onZoomComplete({chart}) {
			// This update is needed to display up to date zoom level in the title.
			// Without this, previous zoom level is displayed.
			// The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
			chart.update('none');
		  }
		}
	  };

	const options = {
		plugins: {
			zoom: zoomOptions,
			legend: {
				display: true
			}
		},
		scales: {
            y: {
                min: -30,
                max: 80
            }
		}
	}

	
	const myChartRef = useRef();

	const resetZoom = () => {
		const chart = myChartRef.current;
    	chart.resetZoom();
	}

	return (
		<div className={classes.container}>
			{sensorData && sensorData.length > 0 ?
				window.innerWidth > 430 ?
					<Line ref={myChartRef} height={350} width={550} data={data} options={options} />
				: 
					<Line ref={myChartRef} height={550} width={550} data={data} options={options} />
			: null}
			
			{sensorDataIndex && sensorDataIndex.startIndex && sensorDataIndex.endIndex ? 
				<div className={classes.range}>
					<Typography align="center" variant="body1"><strong>Uträknat range</strong></Typography>
					<div className={classes.indexContainer}>
						<Typography className={classes.indexText} variant="body1">Startindex: {sensorDataIndex.startIndex}</Typography>
						<Typography className={classes.indexText} variant="body1">Slutindex: {sensorDataIndex.endIndex}</Typography>
					</div>
				</div>
            :null}
			<div className={classes.buttonWrapper}>
				<button onClick={() => resetZoom()} className={classes.button}>Nollställ Zoom</button>
			</div>
		</div>
	);
	
}
export default GraphGeneratorForAnalyze;