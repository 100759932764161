import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils"
import { CURRENT_USER } from "../../utils/AppUtils";

export const ACTION_TYPES = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_ERROR_MESSAGE_TO_NULL: 'SET_ERROR_MESSAGE_TO_NULL'
}

function isAdmin() {
    let isAdmin = false;

    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    if(currentUser && currentUser.role === "admin"){
        isAdmin = true;
    }

    return isAdmin;
}

const initState = {
    token: '',
    role: '',
    loading: false,
    errorMessage: null,
    isAdmin: isAdmin(),
    isAuthenticated: !!JSON.parse(localStorage.getItem(CURRENT_USER)),
    currentUser: JSON.parse(localStorage.getItem(CURRENT_USER))
}

const AuthReducer = (state = initState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SET_ERROR_MESSAGE_TO_NULL:
            return {
                errorMessage: null
            }
        case REQUEST(ACTION_TYPES.LOGIN):
        case REQUEST(ACTION_TYPES.LOGOUT):
            return {
                ...state,
                loading: true,
                errorMessage: null
            };
        case FAILURE(ACTION_TYPES.LOGIN):
        case FAILURE(ACTION_TYPES.LOGOUT):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        case SUCCESS(ACTION_TYPES.LOGIN):
            return {
                ...state,
                loading: false,
                currentUser: action.currentUser,
                isAdmin: isAdmin(),
                isAuthenticated: true,
                errorMessage: null
            };
        case SUCCESS(ACTION_TYPES.LOGOUT):
            return {
                ...state,
                loading: false,
                isAdmin: isAdmin(),
                isAuthenticated: false,
                currentUser: null,
                errorMessage: null
            };
        default:
            return state;
    }
}

export default AuthReducer;

