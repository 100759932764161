import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { archiveRideById, getAllRidesWithCompletedAnalysis } from '../../store/actions/RideActions';
import { connect, useDispatch } from 'react-redux';
import { Pagination, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';
import { COMPLETED_ANALYSIS_URL, DESC, ID, START_ANALYSIS_URL, UPDATED_AT, USER_EMAIL } from '../../utils/AppUtils';
import { NavLink } from 'react-router-dom';
import TableCommon from '../TableCommon';
import SearchBar from '../SearchBar';
import { useBetween } from 'use-between';
import { useShareableState } from '../utils/SharableStates';
import { ACTION_TYPES } from '../../store/reducers/UtilsReducer';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            position: 'relative',
          },
          buttonsContainer: {
            display: 'flex'
          },
        checkSpan: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#aee6ae',
            borderRadius: '0.3rem',
            padding: '7px 10px',
            color: 'darkGreen',
            margin: '0 0.5rem'
        },
        icon: {
            marginRight: 5,

        },
        link: {
            padding: '0.5rem 0.9rem',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.875rem',
            borderRadius: '0.3rem',
            fontWeight: '500',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 10px 0 0'   
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        iconButton: {
            padding: '5px 10px',
            backgroundColor: 'red',
            borderRadius: '0.3rem',
            '&:hover': {
                backgroundColor: 'red'
            }
        },
        deleteIcon: {
            
        },
        dialogRoot: {
            '& .MuiBackdrop-root':{
                backgroundColor: 'rgba(0, 0, 0, 0.02)'
            },
            '& .MuiDialog-scrollPaper': {
                '& .MuiPaper-elevation24': {
                    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)'
                }
            }
        },
        dialogButtonsContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: 15
        },
        buttonArchive: {
            backgroundColor: 'red',
            color: 'white',
            margin: '0 10px 0 0',
            '&:hover': {
                backgroundColor: 'red'
            }
        },
        buttonRegret: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                backgroundColor: 'green'
            }
        },
        buttonClose: {
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            '&:hover': {
                backgroundColor: 'rgb(245 179 53)'
            }
        },
        imageWrapper: {
            display: 'flex',
            justifyContent: 'center',
            '& .MuiSvgIcon-root':{
                fontSize: '4rem'
            }
        }
}})

function AnalyzesDone({getAllRidesWithCompletedAnalysis, loading, rides, totalNumOfRides, archiveRideById, reRender, errorMessage, status, searchTerm}) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Set 'updated_at' as initial sortField for this component
    const [sort, setSort] = useState({
        field: UPDATED_AT,
        sortDirection: DESC
    })
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    const [open, setOpen] = useState(false);
    const [rideIdToArchive, setRideIdToArchive] = useState(null);
    const [errorMsg,setErrorMsg] = useState('');
    const {startSearch, setStartSearch} = useBetween(useShareableState)
    const [tempSearchTerm, setTempSearchTerm] = useState('');

    const handleClickOpen = (rideId) => {
        setRideIdToArchive(rideId);
        dispatch({ type: RIDE_ACTION_TYPES.SET_STATUS_TO_NULL }) 
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
        dispatch({ type: RIDE_ACTION_TYPES.SET_ERROR_MESSAGE_TO_NULL}) 
    };

    const handleArchiveRide = () => {
        //Set reRender prop to false. It will then be set to true if request to archive ride is succesful. This way makes it possible to reload table when a ride is archived
        dispatch({ type: RIDE_ACTION_TYPES.SET_RERENDER_AS_FALSE })
        dispatch({ type: RIDE_ACTION_TYPES.SET_LOADING_AS_TRUE })
        archiveRideById(rideIdToArchive)
        setRideIdToArchive(null);
    };

    //Columns to use as header for table
    const columns = [
        { field: ID, headerName: 'Ride ID', flex: 0.1, headerClassName: 'super-app-theme--header'},
        { field: USER_EMAIL, headerName: 'Användare', flex: 0.2, headerClassName: 'super-app-theme--header' },
        { field: UPDATED_AT, headerName: 'Datum för analys', flex: 0.4, headerClassName: 'super-app-theme--header' },
        { 
            renderCell: (params) => {
                return (
                    <div className={classes.buttonsContainer}>
                        <NavLink className={classes.link} to={COMPLETED_ANALYSIS_URL + params.row.pathUrl}>Gå till analys</NavLink>
                        <IconButton className={classes.iconButton} onClick={() => handleClickOpen(params.row.id)}>
                            <DeleteForeverIcon sx={{ color: 'white' }} className={classes.deleteIcon} fontSize="medium"/>
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{root: classes.dialogRoot}}
                        >
                            {
                                !loading && !errorMsg && !status ? 
                                    <div>
                                        <DialogTitle id="alert-dialog-title">
                                        {"Är du säker på att du vill arkivera denna ritt?"}
                                        </DialogTitle>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            En arkiverad ritt innebär att all insamlad data och historik kommer att finnas kvar, men den kommer inte vara synlig varken i shopen eller i webtoolet.
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogButtonsContainer}>
                                            <Button className={classes.buttonArchive} variant='contained' onClick={handleArchiveRide}>Arkivera</Button>
                                            <Button className={classes.buttonRegret} variant='contained' onClick={handleClose} autoFocus>
                                                Ångra val
                                            </Button>
                                        </DialogActions>
                                    </div>
                                : !loading && errorMsg ? 
                                    <div>
                                        <DialogTitle id="alert-dialog-title">
                                        {errorMessage}
                                        </DialogTitle>
                                        <DialogContent>
                                                <div className={classes.imageWrapper}>
                                                    <ErrorIcon className={classes.errorIcon} color='error'/>
                                                </div>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogButtonsContainer}>
                                            <Button className={classes.buttonClose} variant='contained' onClick={handleClose} autoFocus>
                                                Stäng
                                            </Button>
                                        </DialogActions>
                                    </div>
                                : !loading && status === 200 ? 
                                    <div>
                                        <DialogTitle id="alert-dialog-title">
                                        {"Ritten har nu blivit arkiverad!"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <div className={classes.imageWrapper}>
                                                <CheckCircleIcon className={classes.successIcon} color='success'/>
                                            </div>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogButtonsContainer}>
                                            <Button className={classes.buttonClose} variant='contained' onClick={handleClose} autoFocus>
                                                Stäng
                                            </Button>
                                        </DialogActions>
                                    </div>
                                : null
                               
                            }
                        </Dialog>
                    </div>
                )
            },
            field: "button",
            headerName: "",
            flex: 0.160,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'super-app-theme--header'
        }
    ];

    useEffect(() => {
        getAllRidesWithCompletedAnalysis(paginationModel, navigate, sort, tempSearchTerm);
    }, [paginationModel, sort, reRender]);

    useEffect(() => {
        if(startSearch) {
            setTempSearchTerm(searchTerm)
            getAllRidesWithCompletedAnalysis(paginationModel, navigate, sort, searchTerm);
            setStartSearch(false)
        }
    }, [searchTerm]);

    useEffect(() => {
        setErrorMsg(errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        return () => {
            dispatch({type: RIDE_ACTION_TYPES.CLEAR_RIDES })
            dispatch({type: RIDE_ACTION_TYPES.CLEAR_TOTAL_NUMBER_OF_RIDES})
            dispatch({ type: RIDE_ACTION_TYPES.SET_ERROR_MESSAGE_TO_NULL})
            dispatch({ type: RIDE_ACTION_TYPES.SET_STATUS_TO_NULL})
            dispatch({ type: RIDE_ACTION_TYPES.SET_RERENDER_AS_FALSE })
            dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_TERM, payload: '' })
        }
    },[]);

    const handleSortModelChange = (sortModel) => {
        let sort = sortModel[0];
        setSort({
            field: sort.field,
            sortDirection: sort.sort
        })
        
    }

    const handlePaginationModelChange = (paginationModel) => {
        setPaginationModel({
            page: paginationModel.page,
            pageSize: paginationModel.pageSize
        })
        
    }

    return (

        <div className={classes.root}>
            {!loading && rides && totalNumOfRides !== null ?
                <div>
                    <Typography variant='h1'>Färdiga analyser</Typography>
                    <Typography variant='body1'>Här ser du alla färdiganalyserade ritter.</Typography>
                    <br></br>
                    <SearchBar />
                    <TableCommon 
                        columns={columns} 
                        rows={rides.map(ride => ({
                            id: ride.id,
                            userEmail: ride.userEmail,
                            updatedAt: new Date(Date.parse(ride.updatedAt)).toLocaleString("sv-SE").toString(),
                            pathUrl: ride.pathUrl
                        }))} 
                        loading={loading} 
                        totalElements={totalNumOfRides}
                        handleSortModelChange={handleSortModelChange}
                        handlePaginationModelChange={handlePaginationModelChange}
                        paginationModel={paginationModel}
                    />
                         
                </div>
                :
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
        </div>
    )
}

const mapStateToProps = ({ ride, utils }) => ({
    rides: ride.ridesCompleted,
    loading: ride.loading,
    totalNumOfRides: ride.totalNumOfRides,
    status: ride.status,
    errorMessage: ride.errorMessage,
    reRender: ride.reRender,
    status: ride.status,
    searchTerm: utils.searchTerm
})

const mapDispatchToProps = (dispatch) => {
    return {
        getAllRidesWithCompletedAnalysis: (pagination, navigate, sort, searchTerm) => dispatch(getAllRidesWithCompletedAnalysis(pagination, navigate, sort, searchTerm)),
        archiveRideById: (rideId) => dispatch(archiveRideById(rideId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzesDone);