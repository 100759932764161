import { Box, Container, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useShareableState } from "./utils/SharableStates";
import { useBetween } from "use-between";
import { ACTION_TYPES } from "../store/reducers/UtilsReducer";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        searchContainer: {
            margin: 0,
            padding: '1rem 0',
            display: 'flex',
            justifyContent: 'center',
            '@media (min-width: 800px)': {
                justifyContent: 'start'
            }
        },
        searchField: {
            '@media (min-width: 300px)': {
                width: 260,
            },
            '@media (min-width: 400px)': {
                width: 360,
            },
            '@media (min-width: 800px)': {
                width: 450,
            }
        },
        searchButton: {
            textDecoration: 'none',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none'
        }
    }
})

function SearchBar({}){
    const classes = useStyles();
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const {startSearch, setStartSearch} = useBetween(useShareableState)
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_TERM, payload: tempSearchTerm });
        setStartSearch(true);
    }

    const handleChange = (event) => {
        setTempSearchTerm(event.target.value);
    }

    return (
        <Box className={classes.searchContainer}>
            <form onSubmit={handleSubmit}>
                <TextField
                    className={classes.searchField}
                    id="search"
                    variant="outlined"
                    value={tempSearchTerm}
                    onChange={handleChange}
                    placeholder="Sök på e-postadress..."
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <button type="submit" className={classes.searchButton}>
                                <SearchIcon/>
                            </button>
                        </InputAdornment>
                        ),
                    }}
                />
            </form>
      </Box>
    )
}


export default SearchBar;