import { makeStyles} from "@material-ui/core";
import {ERROR_URL, GRAPH_FOR_SPECIFIC_RIDE, LOGIN_URL} from "../utils/AppUtils"
import { useLocation } from 'react-router';
import Login from "./pages/Login";
import { connect } from "react-redux";
import NavBar from "./NavBar";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: 'white',
            minHeight: '100vh'
        },
        page: {
            backgroundColor: 'white',
            padding: 20,
            marginLeft: 'auto',
            marginRight: 'auto',
            '@media (min-width: 768px)': {
                padding: 16,
                maxWidth: '80rem'
            },
        },
    }
  })

function Layout({ children}) {
    const classes = useStyles();
    const location = useLocation();

    return (
        <div>
            <RenderPage />
        </div>
    )

    function RenderPage() {
        if(location.pathname === LOGIN_URL){
            return <Login />
        } else {
            
                const showNavbar = checkShowNavbar();
                return <RootComponent showNavbar={showNavbar}/>
            
        }
    }

    function checkShowNavbar(){
        if(location.pathname.startsWith(GRAPH_FOR_SPECIFIC_RIDE)){
            return false;
        } else if(location.pathname.startsWith('/analyzes/completed/')){
            return false;
        } else if(location.pathname === ERROR_URL){
            return false;
        }else if(location.pathname.startsWith('/analyzes/saved/')){
            return false;
        }
        return true;
    }
    
    function RootComponent({showNavbar}) {
        return (
            <div className={classes.root}>
                {showNavbar ? <NavBar/> : null}
                
                <div className={classes.page}>
                    { children }
                </div>
            </div>
          )
    }

}

const mapStateToProps = ({ auth }) => ({
    isAuthenticated: auth.isAuthenticated
})

export default connect(mapStateToProps, null)(Layout);