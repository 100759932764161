import { Box, Container, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => {
    return {
        tableContainer: {
            margin: '0',
                boxShadow: 2,
                border: 2,
                '& .super-app-theme--header': {
                backgroundColor: 'lightGray',
                color: 'white'
                },
                '& .MuiDataGrid-cellContent': {
                    color: 'gray',
                    fontWeight: '500'
                }
        }
    }
})

function TableCommon({columns, rows, loading, totalElements, handleSortModelChange, paginationModel, handlePaginationModelChange}){
    const classes = useStyles();

    return (
            <Box className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    rows={rows}
                    rowCount={totalElements}
                    columns={columns}
                    paginationMode='server'
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    sortingOrder={['asc', 'desc']}
                    loading={loading}
                    disableColumnFilter
                    pageSizeOptions={[10, 25, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    getRowId={(row) => row.id}
                />
            </Box>
    )
}


export default TableCommon;