import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@material-ui/core';
import Layout from './components/Layout';
import AnalyzesToDo from './components/pages/AnalyzesToDo';
import AnalyzesDone from './components/pages/AnalyzesDone';
import { ANALYZES_DONE_URL, START_ANALYSIS_URL, LOGIN_URL, TODO_ANALYZES_URL, DO_ANALYSIS_URL, NOT_ALLOWED_URL, COMPLETED_ANALYSIS_URL, CONFIRMATION_PAGE_URL, ERROR_URL, GRAPH_FOR_SPECIFIC_RIDE, ANALYZES_NOT_PURCHASED_URL } from './utils/AppUtils';
import Login from './components/pages/Login';
import StartAnalysis from './components/pages/StartAnalysis';
import DoAnalysis from './components/pages/DoAnalysis';
import NotAllowed from './components/NotAllowed';
import CompletedAnalysis from './components/pages/CompletedAnalysis';
import { RequireAdmin } from './components/utils/AuthenticationUtils';
import ConfirmationPage from './components/pages/ConfirmationPage';
import DefaultErrorPage from './components/pages/DefaultErrorPage';
import GraphForSpecificRide from './components/pages/GraphForSpecificRide';
import AnalyzesNotPurchased from './components/pages/AnalyzesNotPurchased';

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway,sans-serif!important',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
      color: 'gray'
    },
    h1: {
      fontSize: '1.7rem',
      fontWeight: '500',
      margin: '12px 0px',
    '@media (min-width: 1024px)': {
      fontSize: '2.4rem'
    },
  }
  },
  palette: {
    primary: {
      main: 'rgb(245 179 53)'
    },
    secondary: {
      main: 'rgb(245 179 53)'
    }
  },
})



function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route 
              path={TODO_ANALYZES_URL} 
              element={
                <RequireAdmin>
                  <AnalyzesToDo/>
                </RequireAdmin>
              }
            />
            <Route
              path={ANALYZES_DONE_URL} 
              element={
                <RequireAdmin>
                  <AnalyzesDone/>
                </RequireAdmin>
              }
            />
            <Route
              path={ANALYZES_NOT_PURCHASED_URL} 
              element={
                <RequireAdmin>
                  <AnalyzesNotPurchased/>
                </RequireAdmin>
              }
            />
            <Route 
              path={`${START_ANALYSIS_URL}:rideId`} 
              element={
                <RequireAdmin>
                  <StartAnalysis/>
                </RequireAdmin>
              }
            />
            <Route 
              path={`${DO_ANALYSIS_URL}:rideId`} 
              element={
                <RequireAdmin>
                  <DoAnalysis/>
                </RequireAdmin>
              }
            />
            <Route 
              path={`${CONFIRMATION_PAGE_URL}:rideId`} 
              element={
                <RequireAdmin>
                  <ConfirmationPage/>
                </RequireAdmin>
              }
            />
            <Route 
              path={ERROR_URL}
              element={
                <DefaultErrorPage/>
              }
            />
            <Route 
              path={`${COMPLETED_ANALYSIS_URL}:pathUrl`} 
              element={
                //<RequireUser>
                  <CompletedAnalysis/>
                //</RequireUser>
              }
            />
            <Route 
              path={`${GRAPH_FOR_SPECIFIC_RIDE}:rideId`} 
              element={
                  <GraphForSpecificRide/>
              }
            />
            <Route path={LOGIN_URL} element={<Login/>}/>
            <Route path={NOT_ALLOWED_URL} element={<NotAllowed/>}/>
            <Route path="*" element={<Navigate replace to={TODO_ANALYZES_URL}/>}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
