import { FAILURE, REQUEST, SUCCESS } from "../../utils/ActionTypeUtils";

export const ACTION_TYPES = {
    FETCH_USER_BY_RIDE_ID: 'FETCH_USER_BY_RIDE_ID',
    CLEAR_USER: 'CLEAR_USER'
}

const initState = {
    loading: false,
    errorMessage: null,
    user: null
};

const UserReducer = (state = initState, action) => {
    switch(action.type) {
        case ACTION_TYPES.CLEAR_USER:
            return {
                ...state,
                user: null
            };
        case REQUEST(ACTION_TYPES.FETCH_USER_BY_RIDE_ID):
            return {
                ...state,
                errorMessage: null,
                loading: true,
                user: {}
            };
        case FAILURE(ACTION_TYPES.FETCH_USER_BY_RIDE_ID):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_USER_BY_RIDE_ID):
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        default: 
            return state;
    }
}

export default UserReducer;