import { combineReducers } from 'redux';
import RideReducer from './RideReducer';
import UserReducer from './UserReducer';
import AuthReducer from './AuthReducer';
import ErrorHandlerReducer from './ErrorHandlerReducer';
import SensorDataReducer from './SensorDataReducer';
import UtilsReducer from './UtilsReducer';

const rootReducer = combineReducers({
    ride: RideReducer,
    user: UserReducer,
    auth: AuthReducer,
    errorHandler: ErrorHandlerReducer,
    sensorData: SensorDataReducer,
    utils: UtilsReducer
})

export default rootReducer;