import { useEffect } from "react";
import { useRef } from "react";

export const LOGO_URL = "https://riders-position.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo_dark.192e52b7.png&w=64&q=75";
export const TODO_ANALYZES_URL = "/analyzes/todo";
export const ANALYZES_DONE_URL = "/analyzes/done";
export const ANALYZES_NOT_PURCHASED_URL = "/analyzes/not-purchased"
export const START_ANALYSIS_URL = "/analyzes/start-analysis/";
export const DO_ANALYSIS_URL = "/analyzes/do-analysis/";
export const GRAPH_FOR_SPECIFIC_RIDE = "/graph/";
export const COMPLETED_ANALYSIS_URL = "/analyzes/completed/";
export const CONFIRMATION_PAGE_URL = "/analyzes/saved/"
export const NOT_ALLOWED_URL = "/not-allowed";
export const LOGIN_URL = "/login";
export const ERROR_URL = "/error";
export const ACCESS_TOKEN = "accessToken";
export const USER_ROLE = "role";
export const CURRENT_USER = "currentUser";
export const API_BASE_URL = "https://api.ridersposition.com/api/";
export const ANALYSIS_BACKEND_BASE_URL = "http://localhost:8000/"
export const ANALYSIS_IN_PROCESS = "ANALYSIS_IN_PROCESS";
export const ANALYSIS_COMPLETED = "ANALYSIS_COMPLETED";
export const ANALYSIS_NOT_PURCHASED = "ANALYSIS_NOT_PURCHASED";
export const BG_SENDER_SYSTEM = "rider-analysis-frontend";
export const SITTING_TROT = "SITTING_TROT";
export const RISING_TROT = "RISING_TROT";
export const SITTING_TROT_SWE = "trav nedsittning";
export const RISING_TROT_SWE= "trav lättridning";
export const SITTING_TROT_ENG = "sitting trot";
export const RISING_TROT_ENG = "rising trot";
export const ENG = "ENG";
export const ID = "id";
export const USER_EMAIL = "userEmail";
export const UPDATED_AT = "updatedAt";
export const CREATED_AT = "createdAt";
export const DESC = "desc";
export const ASC = "asc";
export const PAGE = "page";
export const PAGE_SIZE = "pageSize";
export const STATUS = "status";
export const SORT_FIELD = "sortField";
export const SORT_DIRECTION = "sortDirection";
export const H_SEARCH_TERM = "searchTerm";

export const evaluateGaitTypeSwe = (gaitType) => {

    switch(gaitType) {
        case SITTING_TROT:
            return SITTING_TROT_SWE;
        case RISING_TROT: 
            return RISING_TROT_SWE;
        default: 
            return "OKÄND";    
    }
}

export const evaluateGaitTypeEng = (gaitType) => {

  switch(gaitType) {
      case SITTING_TROT:
          return SITTING_TROT_ENG;
      case RISING_TROT: 
          return RISING_TROT_ENG;
      default: 
          return "Unkwown";    
  }
}

export function useIsComponentMounted() {
    const isComponentMounted = useRef(false);
  
    useEffect(() => {
      isComponentMounted.current = true;
      return () => {
        isComponentMounted.current = false;
      };
    }, []);
  
    return isComponentMounted;
  }