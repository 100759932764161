import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { Line, Chart as Chart2 } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => {
    return {
       container: {
			width: '95%',
			margin: 'auto',
			padding: '0.5rem',
			backgroundColor: 'white',
	   },
	   buttonWrapper:{
			display: 'flex',
			justifyContent:'center',
			padding: '2rem 0'
	   },
	   button: {
		padding: '0.7rem 1.5rem',
		color: 'white',
		backgroundColor: 'rgb(245 179 53)',
		textDecoration: 'none',
		fontSize: '.975rem',
		borderRadius: '0.7rem',
		fontWeight: '500',
		cursor: 'pointer',
		border: 'none',
		"&:hover": {
			color: '#a6a6a6'
		}
		}
}})


function GraphGeneratorForCustomer({ graphData, locale, sensorDataIndex }) {
	const classes = useStyles();
	const [labels, setLabels] = useState([]);
    const [accX, setAccX] = useState([]);
    const [accY, setAccY] = useState([]);
    const [accZ, setAccZ] = useState([]);


	function setXYZ(sensorData) {
		let tempX = [];
		let tempY = [];
		let tempZ = [];
		let tempLabels = [];

		for (let i = 0; i < sensorData.length; i++) {
			tempX.push(sensorData[i].accX);
			tempY.push(sensorData[i].accY);
			tempZ.push(sensorData[i].accZ);
			tempLabels.push(i);
		}
		setAccX(tempX);
		setAccY(tempY);
		setAccZ(tempZ);
		
		setLabels(tempLabels)
	}

	useEffect(() => {
		setXYZ(graphData)
	},[]);

	const data = {
		labels: labels,
		datasets: [

			//Hiding the red X-line after request from Mari and Charlotta
			// {
			// 	label: "X(Sida till sida)",
			// 	fill: false,
			// 	lineTension: 0.1,
			// 	backgroundColor: "#F5B335",
			// 	borderWidth: 1.8,
			// 	borderColor: "#F5B335",
			// 	borderCapStyle: "butt",
			// 	borderDash: [],
			// 	borderDashOffset: 0.0,
			// 	borderJoinStyle: "miter",
			// 	pointRadius:0,
			// 	data: accX,
			// },
            {
				label: locale == "ENG" ?  "Y(Up and down)" : "Y(Upp och ned)"  ,
				fill: false,
				lineTension: 0.1,
				backgroundColor: "#E85B0A",
				borderWidth: 1.8,
				borderColor: "#E85B0A",
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointRadius: 0,
				data: accY,
			},
            {
				label:  locale == "ENG" ? "Z(Forward and backwards)" : "Z(Fram och tillbaka)",
				fill: false,
				lineTension: 0.1,
				backgroundColor: "#766e632",
				borderColor: "#766e632",
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderWidth: 1.8,
				borderJoinStyle: "miter",
				pointRadius: 0,
				data: accZ,
			},
		],
	};

	const zoomOptions = {
		limits: {
		  x: {min: 0, max: 5050, minRange: 50},
		  y: {min: -100, max: 100, minRange: 120}
		},
		pan: {
		  enabled: true,
		  mode: 'xy',
		},
		zoom: {
		  wheel: {
			enabled: true,
		  },
		  pinch: {
			enabled: true
		  },
		  mode: 'x',
		  onZoomComplete({chart}) {
			// This update is needed to display up to date zoom level in the title.
			// Without this, previous zoom level is displayed.
			// The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
			chart.update('none');
		  }
		}
	  };

	const options = {
		plugins: {
			zoom: zoomOptions,
			legend: {
				display: true
			}
		},
		scales: {
            y: {
                min: -30,
                max: 80
            }
        }
	}

	const myChartRef = useRef();

	const resetZoom = () => {
		const chart = myChartRef.current;
    	chart.resetZoom();
	}

	return (
		<div className={classes.container}>
			{accX && accX.length > 0 && accY && accY.length > 0 && accZ && accZ.length > 0 ?
				window.innerWidth > 430 ?
					<Line ref={myChartRef} height={350} width={550} data={data} options={options} />
				: 
					<Line ref={myChartRef} height={500} width={550} data={data} options={options} />
			: null}

			<div className={classes.buttonWrapper}>
				<button onClick={() => resetZoom()} className={classes.button}>Nollställ Zoom</button>
			</div>
		</div>
	);
	
}
export default GraphGeneratorForCustomer;