import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { NavLink } from "react-router-dom";
import { TODO_ANALYZES_URL } from "../../utils/AppUtils";
import { Stack } from "@mui/material";
import { ACTION_TYPES as RIDE_ACTION_TYPES } from '../../store/reducers/RideReducer';
import { useEffect } from "react";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '4rem 0px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'gray'
        },
        successMessage: {
            fontWeight: 'bold'
        },
        iconWrapper: {
            padding: '2rem 0'
        },
        link: {
            padding: '0.6rem 1.3rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'rgb(245 179 53)',
            textDecoration: 'none',
            fontSize: '.975rem',
            borderRadius: '0.9rem',
            fontWeight: '500',
            marginTop: '2rem'
          },
    }
  })

function ConfirmationPage({response}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    //const {rideId} = useParams();

    useEffect(() => {
        return () => {
            dispatch({type: RIDE_ACTION_TYPES.CLEAR_RESPONSE })
        }
    },[]);

    return (
        <div className={classes.root}>
           {response && response !== '' ? 
                <div className={classes.container}>
                    <Typography variant="h6">Bra jobbat!</Typography>
                    <Typography className={classes.successMessage} variant="h5">{response.message}</Typography>
                    <div className={classes.iconWrapper}>
                        <CheckCircleOutlineIcon  
                            sx={{
                                fontSize: 150,
                                color: "success.light"
                            }}
                        />
                    </div>
                    <div>
                        <NavLink className={classes.link} to={TODO_ANALYZES_URL}>
                            <KeyboardBackspaceIcon/>&nbsp;&nbsp;Gå till startsidan
                        </NavLink>
                    </div>
                    
                </div> 
                : 
                <Stack sx={{ color: 'grey.500', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            }
           
            
        </div>
    )

    
}

const mapStateToProps = ({ride}) => ({
    response: ride.response
})

const mapDispatchToProps = () => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);